import React from 'react'
import Swal from 'sweetalert2'
import { GrCertificate } from 'react-icons/gr'

import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import { fullName } from '@modules/form-wizard/utils'
import { listKeys } from '@helpers/objects'
import SERVICE_PROPS from '@helpers/extract-service-props'
import occupations from '@constants/occupations'
import countries from '@constants/countries'
import islands from '@constants/islands'

import passportPhotoExample from '@helpers/passport-photo-example'

export default class CharacterReference extends React.Component {
  state = {
    loading: false,
  }

  title = 'Character Certificate (Digital)'
  resource = '/police_certificates'
  form_key = 'police_certificate'

  // skip_location = form => form.delivery_method == 'DIGITAL'
  skip_location = true

  get isBahamian() {
    return $app.applicant?.country_of_citizenship == 'bahamas'
  }

  defaults = () => {
    const res = {
      full_name: fullName($app.applicant),
      amount: 1,
    }

    if (this.isBahamian) {
      res.birth_place = 'Bahamas'
      res.passport_issue_country = 'Bahamas'
    }

    return res
  }

  canned = [
    `
    Thank you for your request. We require that you come in person for a finger print scanning.
    Please visit the Royal Bahamas Police Force Head Office at New Providence, Thompson Blvd. to complete your application process
    `,
  ]

  /* The `fee` function is calculating the fee for the service based on the form data. It takes in the
  `form` object as a parameter and returns a number representing the fee. */
  fee = form => {
    const rate = $app.applicant.verified_citizenship ? 5 : 10
    return rate

    // Below code is only needed once multiple copies can be requested
    // if (form.delivery_method == 'DIGITAL') return rate

    // // if the delivery amount is BOTH, the
    // // digital amount will be added to the total before
    // // multiplication of amount requested
    // let amount = form.delivery_method == 'PHYSICAL' ? 0 : rate
    // return amount + (rate * form.copies_requested)
  }

  custom_actions = [
    {
      text: 'Request Prints',
      icon: 'fingerprint',
      fn: async record => {
        const person = listKeys(
          record.user,
          'first_name',
          'middle_name',
          'last_name'
        )
          .filter(n => n)
          .join(' ')
          .capitalize()

        const { isConfirmed } = await Swal.fire({
          icon: 'question',
          title: 'Request Fingerprints',
          text: `Are you sure want to request that ${person} come in for fingerprinting?`,
          showCancelButton: true,
        })

        if (isConfirmed) {
          const { resource, form_key } = this
          this.setState({ loading: true })

          try {
            await $app.axios.put(resource + '/update_application', {
              [form_key]: {
                form_num: record.form_num,
                application_decision: 'processing',
                finger_print_rq: true,
              },
            })

            await $app.axios.post('/emails', {
              email: record.user.email,
              subject: 'MyGateqway Portal Update - Character Reference Request',
              message: `
                Good day ${record.user.first_name.capitalize()},
                <br/>
                In order to complete your Character Reference request, we ask that you
                come in person to the Central Detective Unit located on Thompson Blvd.,
                New Providence for fingerprinting.
                <br/><br />
                Thank you for using MyGateway Portal.
              `,
            })
          } catch (err) {
            console.error(err)
          } finally {
            this.setState({ loading: false })
          }
        }
      },
    },
    {
      text: 'Set Licence #',
      icon: <GrCertificate className='text-blue-600' />,
      roles: ['officer'],
      test: r => r.digital_cert_only,
      fn: async record => {
        const { value: license_numb, isDismissed } = await Swal.fire({
          title: 'Set Licence Number',
          input: 'text',
          inputLabel: 'Tag Number',
          inputPlaceholder: 'Tag Number',
          text: `Please set the applicant's licence #`,
          showCancelButton: true,
          confirmButtonText: 'Set Licence #',
        })

        if (isDismissed) return
        this.setState({ loading: true })

        const { props, resource, form_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/update_application', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'approved',
              license_numb,
            },
          })

          if (location.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'approved'

            history.replace(location.pathname, { ...location.state, view })
          }

          toastr.success('Success', 'Application Licence # set')
        } catch (error) {
          console.error(error)
          toastr.error('Error', 'Unable to set licence #')
          return
        }

        this.setState({ loading: false, record: null })
      }
    },
    {
      text: 'Process Digital Print',
      icon: <GrCertificate className='text-blue-600' />,
      roles: ['senior_officer', 'director'],
      test: r => r.digital_cert_only && ['officer viewed', 'senior officer viewed'].includes(r.application_decision),
      fn: async record => {
        const isFinalApprover = $app.hasAnyRole(['director']);

        const { value, isDismissed } = await Swal.fire({
          title: 'Process Application',
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': isFinalApprover ? "I approve this applicant" : "I recommend this applicant",
            'denied': isFinalApprover ? "I will deny this applicant" : "I do not recommend this applicant",
          },
          showCancelButton: true,
          confirmButtonText: 'Send',
        })

        if (isDismissed) return
        this.setState({ loading: true })

        const { props, resource, form_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/update_application', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: value,
            },
          })

          if (location.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = isFinalApprover ? value : 'senior officer viewed'

            history.replace(location.pathname, { ...location.state, view })
          }

          toastr.success('Success', 'Application processed')
        } catch (error) {
          console.error(error)
          toastr.error('Error', 'Unable to process application')
          return
        }

        this.setState({ loading: false, record: null })
      }
    }
  ]

  validate = form => {
    const updates = {}

    // Below code is only needed once multiple copies can be requested
    // if (form.copies_requested) {
    //   if (form.copies_requested < 1 || form.copies_requested > 10)
    //     return 'For # of copies, please enter an amount between 1 and 10.'
    // }

    switch (form.delivery_method) {
      case 'PHYSICAL':
        updates.physical_copy = true
        updates.digital_cert_only = false
        break
      case 'DIGITAL':
        updates.digital_cert_only = true
        updates.physical_copy = false
        break
      case 'BOTH':
        updates.physical_copy = true
        updates.digital_cert_only = true
        break
      default:
        toastr.warning('Response Needed', 'Please select a type of certificate')
        return false

    }

    if (form.full_name.trim().split(' ').length < 3 && !form.no_middle_name) {
      return 'Please be sure to enter your middle name'
    }

    return updates
  }

  fields = form => [
    {
      name: 'travel_job',
      label: 'Purpose',
      options: ['Travel', 'Employment', 'Immigration'],
      hint: 'Will this document be used for travel, employment or immigration?',
    },
    {
      name: 'digital_cert_only',
      label: 'Digital Certificate?',
      type: 'select:bool',
      hide: true,
      save: true,
      view: true,
    },
    {
      name: 'physical_copy',
      label: 'Physical Copy?',
      type: 'select:bool',
      hide: true,
      save: true,
      view: true,
    },
    {
      name: 'delivery_method',
      type: 'radio',
      view: false,
      label: 'Type of Certificate',
      options: [
        { value: 'DIGITAL', label: 'DIGITAL' },
        // { value: 'PHYSICAL', label: 'PHYSICAL' },
        // { value: 'BOTH', label: 'PHYSICAL & DIGITAL' }
      ],
    },
    // {
    //   name: 'copies_requested',
    //   label: '# of Copies',
    //   type: 'number',
    //   is: 'integer',
    //   min: 1,
    //   hide: form.delivery_method == 'DIGITAL'
    // },
    {
      name: 'passport_verified',
      admin: true,
    },
    'street_address',
    {
      name: 'birth_place',
      label: 'Country Of Birth',
      options: countries,
    },
    {
      name: 'birth_island',
      label: 'Island Of Birth',
      options: islands,
      hide: form.birth_place !== 'Bahamas',
      use_profile: 'island',
    },
    {
      name: 'occupation',
      options: occupations,
    },
    {
      heading: 'Passport Details',
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'passport_number',
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'full_name',
      hint: "Enter the full name on your passport if it's not the same as your NIB",
      save: true,
      view: !!form.full_name,
      required: !form.no_middle_name,
      disabled: form.no_middle_name,
    },
    {
      name: 'no_middle_name',
      view: false,
      type: 'checkbox',
      label: 'I have no middle name',
      required: false,
    },
    {
      name: 'passport_issue_date',
      label: 'Issue Date',
      type: 'date',
      showMonthDropdown: true,
      showYearDropdown: true,
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'passport_expiry_date',
      label: 'Expiration Date',
      type: 'date',
      showMonthDropdown: true,
      showYearDropdown: true,
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'identification',
      type: 'file:image',
      label: 'Identification',
      key: f => f.image[0],
      hide: form.travel_job != 'Travel',
      tooltip: 'Only the information page is required',
      hint: passportPhotoExample,
    },
    {
      name: 'passport_issue_country',
      label: 'Issuing Country',
      hide: form.travel_job != 'Travel',
      disabled: this.isBahamian,
      options: countries,
    },
    {
      name: 'work_permit_upload',
      label: 'Work Permit',
      type: 'file:image',
      key: f => f.image[1],
      hide: this.isBahamian,
    },
  ]

  hidden_fields = form => [
    {
      key: 'image.3',
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Character Certificate (Summary)',
      hide: !form.digital_cert_only,
    },
    {
      key: 'image.4',
      name: 'pdf_upload_2',
      type: 'file:pdf',
      label: 'Character Certificate (Summary)',
      hide: !form.digital_cert_only,
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
