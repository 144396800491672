import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import moment from 'moment'
import countries from '@constants/countries'
import Swal from 'sweetalert2'
import { fullName } from '@modules/form-wizard/utils'

// const today = moment()

export default class ShipSanitationInspection extends React.Component {
  state = {
    loading: false,
    record: null,
    metadata: null,
  }

  metadata = null

  resource = '/ship_sanitation_inspections'
  form_key = 'ship_sanitation_inspection'
  defaults = {}

  // Delete lines as needed
  // free = false
  // postpaid = true
  pay_on_approval = true
  skip_location = true
  hide_buttons = {
    status: true,
    deny: false,
    fee: true,
  }
  // custom_acceptance = true

  async componentDidMount() {
    try {
      const { data } = await $app.axios.get('/agency_services')
      this.setState({
        metadata: data.services.filter(s => s.name === 'ship sanitation inspection')[0]
          ?.metadata,
      })
    } catch (error) {
      console.error(error)
    }
  }

  validate = () => {
    // TODO
    // Use Multiple Photo Attachments Route for certificate uploads

    return true
  }

  foremost = null

  custom_actions = [
    {
      text: 'Confirm Inspection Date',
      icon: 'check-square',
      roles: ['officer'],
      test: r => ['pending', 'processing'].includes(r.application_decision),
      fn: async record => {
        let inspectionDate
        let InspectionTimeRange;

        // Set value of Inspection Date
        const datePrompt = await Swal.fire({
          icon: 'question',
          title: 'Inspection Date?',
          // html: `
          //   What is your recommended inspection date regarding the applicant,
          //   <strong>${fullName(record.user, 'initial')}</strong>?
          // `,
          input: 'date',
          // inputValue: record?.requested_date_time,
          showCancelButton: true,
          // confirmButtonText: 'Send',
          didOpen: () => {
            const today = (new Date()).toISOString();
            Swal.getInput().min = today.split("T")[0];
          }
        })
  
        if (datePrompt.isDismissed) return

        inspectionDate = datePrompt.value

        const { value } = await Swal.fire({
          icon: 'info',
          input: 'select',
          text: 'Please set the time range',
          title: 'Inspection Time Range?',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          inputOptions: {
            '7:30 am - 10:30 am': '7:30 am - 10:30 am',
            '11:00 am - 2:30 pm': '11:00 am - 2:30 pm',
            '3:00 pm - 5:30 pm': '3:00 pm - 5:30 pm',
          },
          inputValue: record?.inspection_time_range,
          // inputPlaceholder: "Select a time range",
        })
    
        InspectionTimeRange = value;
    
        if (value) {
          const { isConfirmed } = await Swal.fire({
            icon: 'question',
            title: 'Confirm Details',
            html: `
              ${fullName(record.user, false)}
              Are you sure you want the inspection date to be: <b>${inspectionDate}</b>
              Are you sure you want the time range to be: <b>${InspectionTimeRange}</b>
            `,
            showCancelButton: true,
            confirmButtonText: 'Submit',
          })
    
          if (isConfirmed) {
            await this.submitToNewLevel('approved', record, inspectionDate, InspectionTimeRange)
          }
        }
      }
    },
  ]

  submitToNewLevel = async (value, record, inspectionDate = null, InspectionTimeRange = null) => {
    const { resource, form_key } = this
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
          inspection_date: inspectionDate,
          inspection_time_range: InspectionTimeRange
        },
      })

      Swal.fire(
        'Submitted!',
        'The application was successfully updated.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to update this application',
        'error'
      )
      return
    }
  }

  columns = props => [
    {
      name: 'Inspection Date',
      selector: row => row.inspection_date ? moment(row.inspection_date).format('MMMM Do YYYY, h:mm a') : 'N/A',
      sortable: true,
      searchable: r => r.inspection_date,
    },
    {
      name: 'Inspection Time',
      selector: row => row.inspection_time_range || 'N/A',
      sortable: true,
      searchable: r => r.inspection_time_range,
    },
  ]

  fields = form => {
    const { metadata } = this.state

    const entry_port_data = metadata
      ?.filter(m => m.desc === 'entry ports')[0]
      ?.data.filter(d => d.active)

    const islands = entry_port_data?.reduce((arr, val) => {
      if (arr.includes(val.island)) return arr
      arr.push(val.island)

      return arr
    }, [])

    const getIslandCities = island =>
      entry_port_data
        ?.filter(data => data.island === island)
        .reduce((arr, val) => {
          if (arr.includes(val.city)) return arr
          arr.push(val.city)

          return arr
        }, [])

    const getEntryPorts = (city, island, method) => {
      let type
      if (method === 'Air') type = 'Airport'
      if (method === 'Sea') type = 'Dock'

      if (!city) {
        return entry_port_data
          ?.filter(data => data.island === island && data.port_type === type)
          .map(data => data.address)
      }

      return entry_port_data
        ?.filter(data => data.city === city && data.port_type === type)
        .map(data => data.address)
    }

    const hasCity = island => {
      return entry_port_data
        ?.filter(data => data.island === island)
        .some(port => port.city)
    }
    
    return [
      '::This request form should be submitted at least two(2) work weeks prior to the requested date for inspection, otherwise an inspector will issue an acceptable inspection date',
      // Agency has the ability to alter the inspection date requested
      {
        name: 'inspection_date',
        type: 'date',
        showYearDropdown: true,
        hide: true,
        view: {
          label: 'Inspection Date',
          value: v => (v ? moment(v).format('MMMM Do YYYY, h:mm a') : 'N/A')
        },
      },
      {
        name: 'requested_date_time',
        label: 'Date of request',
        type: 'date',
        showYearDropdown: true,
        // minDate: today,
      },
      {
        name: 'inspection_time_range',
        label: 'Time Range for request',
        options: [
          { label: '7:30 am - 10:30 am', value: '7:30 am - 10:30 am' },
          { label: '11:00 am - 2:30 pm', value: '11:00 am - 2:30 pm' },
          { label: '3:00 pm - 5:30 pm', value: '3:00 pm - 5:30 pm' },
        ],
      },
      {
        name: 'vessel_name',
        label: 'Name of vessel',
      },
      {
        name: 'registration_imo',
        label: 'Registration / IMO #',
      },
      {
        name: 'flag',
        label: 'Flag',
        options: countries,
        hint: 'Country where the ship is registered'
      },
      {
        name: 'gross_tonnage',
        label: 'Gross Tonnage',
        type: 'float',
        hint: 'in tonnes'
      },

      {
        name: 'net_tonnage',
        label: 'Net Tonnage',
        type: 'float',
        hint: 'in tonnes'
      },

      ':: Port of Arrival',
      {
        name: 'entry_port_island',
        label: 'Island',
        options: islands,
        callback: v => {
          if (!hasCity(v)) return { entry_port_city: '' }
        },
      },
      {
        name: 'entry_port_city',
        options: form.entry_port_island
          ? getIslandCities(form.entry_port_island)
          : [],
        hide: !hasCity(form.entry_port_island),
      },
      {
        name: 'entry_port_type',
        label: 'Entry Method',
        // options: ['Air', 'Sea', 'Postal', 'FedEx', 'Other'],
        options: ['Sea'],
        callback: v => {
          if (v != 'Other') {
            return { other_port_type: '' }
          }
        },
      },
      // {
      //   name: 'other_port_type',
      //   label: 'Other Method',
      //   hide:
      //     form.entry_port_type != 'Other' &&
      //     form.entry_port_type !== form.other_port_type,
      //   view: false,
      // },
      {
        name: 'entry_port',
        label: 'Port of Entry',
        options: form.entry_port_island
          ? getEntryPorts(
              form.entry_port_city,
              form.entry_port_island,
              form.entry_port_type
            )
          : [],
        // hide:
        //   form.entry_port_type === 'Other' ||
        //   form.entry_port_type === 'FedEx' ||
        //   form.entry_port_type === 'Postal',
      },

      '::SPACER',
      {
        name: 'expected_arrival_date_time',
        label: 'Expected Date of Arrival',
        type: 'date',
        showYearDropdown: true,
        // minDate: today,
      },
      {
        name: 'expected_time_range',
        label: 'Expected Time Range of Arrival',
        options: [
          { label: '7:30 am - 10:30 am', value: '7:30 am - 10:30 am' },
          { label: '11:00 am - 2:30 pm', value: '11:00 am - 2:30 pm' },
          { label: '3:00 pm - 5:30 pm', value: '3:00 pm - 5:30 pm' },
        ],
      },
      {
        name: 'berth',
        label: 'Berth'
      },
      {
        name: 'previous_port',
        label: 'Previous Port',
      },
      {
        name: 'next_port',
        label: 'Next Port',
      },

      ':: Operator/Agent Details',
      {
        name: 'contact_first_name',
        label: 'First Name',
      },
      {
        name: 'contact_middle_name',
        label: 'Middle Name',
        required: false,
      },
      {
        name: 'contact_last_name',
        label: 'Last Name',
      },
      {
        name: 'contact_phone',
        label: 'Phone',
        type: 'phone',
      },
      {
        name: 'contact_email',
        label: 'Email Address',
        type: 'email'
      },

      ':: Uploads',
      {
        name:'control_cert_upload',
        key: 'image.0',
        label:'Ship Sanitation Exemption or Control Certificate(SSECC)',
        type: 'file:all',
      },
  ]
}

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
