import React, { Fragment, useEffect, useState } from 'react'
import toastr from '@modules/toastr'
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component'
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu'
import Loader from '@shared/loader'
import StateStore from '@helpers/state-store'

const RoleManagement = () => {
  // console.log($app)
  const [loading, setLoading] = useState(false)
  const [ministry, setMinistry] = useState(null)
  const [ministryOptions, setMinistryOptions] = useState([])
  const [selected, setSelected] = useState([])
  const [clear] = useState(false)
  const [params, setParams] = StateStore({
    page: 1,
    per_page: 20,
  })

  // const [record, setRecord] = useState(null)
  const [listing, setListing] = useState([])
  const [refresh, setRefresh] = useState(true)

  const [availableRoles, setAvailableRoles] = useState([])
  const [ministryRoles, setMinistryRoles] = useState([])

  // List of all base ministry roles both keys and Readable English
  const ministryRoleList = [{key: 'police', label: 'Royal Bahamas Police Force'}, {key: 'insurance', label: 'Insurance'}, {key: 'road_traffic', label: 'Road Traffic Dept.'},
                            {key: 'registrar_general', label: 'Registrar General Dept.'}, {key: 'passport', label: 'Passport Office'}, {key: 'post_office', label: 'Post Office'},
                            {key: 'attorney_general', label: 'Attorney General'}, {key: 'housing', label: 'Housing'}, {key: 'mof_revenue', label: 'Ministry of Finance Revenue'},
                            {key: 'opm', label: 'Office of the Prime Minister'}, {key: 'mota', label: 'Ministry of Tourism & Aviation'}, {key: 'moa', label: 'Ministry of Agriculture'},
                            {key: 'prd', label: 'Parliament'}, {key: 'mwt', label: 'Ministry of Works & Tourism'}, {key: 'moa_marine', label: 'Ministry of Marine Agriculture'},
                            {key: 'dol', label: 'Ministry of Labour & the Public Service'}, {key: 'national_security', label: 'Ministry of National Security'},
                            {key: 'moea', label: 'Ministry of Economic Affairs'}, {key: 'moss', label: 'Ministry of Social Services'}, {key: 'culture', label: 'Division of Culture (MYSC)'},
                            {key: 'sports', label: 'Division of Sports (MYSC)'}, {key: 'youth', label: 'Division of Youth (MYSC)'}, {key: 'mysc', label: 'Ministry of Youth, Sports & Culture'},
                            {key: 'caab', label: 'Civil Aviation Authority Bahamas'}]

  // Reload records on refresh or ministry change
  useEffect(() => {
    if (ministry) refresh && fetchRecords();
  }, [refresh, params.page, ministry])

  // Reload availableRoles on refresh or ministry change
  useEffect(() => {
    if (ministry) refresh && fetchRoles();
  }, [refresh, ministry])

  // Load all ministry roles on refresh and page load
  useEffect(() => {
    refresh && fetchMinistryRoles()
  }, [refresh])

  // Set the Ministry options select list based on roles of admin
  useEffect(() => {
    ministryRoles && setMinistryOptions(ministryRoles.map(x => {
      return ministryRoleList.find(f => f.key == x)
    }))
  }, [ministryRoles])

  // Network request to API to retrieve Ministry Roles based on User token
  const fetchMinistryRoles = async () => {
    setRefresh(false);
    setLoading(true)

    try {
      const { data } = await $app.axios.get('role_management/ministry_roles')

      setMinistryRoles(data.records);
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
      Swal.fire(
        'Oops...',
        'There was an error while loading roles',
        'error'
      )
    }
  }

  // Network request to API to retrieve Available Roles to set as permissions based on User token
  const fetchRoles = async () => {
    setRefresh(false);
    setLoading(true)

    try {
      const { data } = await $app.axios.get('role_management/roles')

      setAvailableRoles(data.records);
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
      Swal.fire(
        'Oops...',
        'There was an error while loading roles',
        'error'
      )
    }
  }

  // Network request to API to retrieve all users within the same Ministry based on User token
  const fetchRecords = async (search_nib = false, NIB = '') => {
    setRefresh(false);
    setLoading(true)

    try {
      let params = `?ministry=${ministry}`
      if (search_nib) params = params + `&nib=${NIB}`
      const { data } = await $app.axios.get('role_management' + params)

      // if (search_nib && NIB !== '') setRecord(data.records);
      if (!search_nib) setListing(data.records.filter(f => f.id !== $app?.current_user?.id));
      setLoading(false)

      if (search_nib && NIB !== '') return data.records
      return null

    } catch (err) {
      setLoading(false)
      console.error(err)
      Swal.fire(
        'Oops...',
        'There was an error while loading records',
        'error'
      )
      return false
    }
  }

  const changeMinistry = (opt) => {
    setMinistry(opt)
    setRefresh(true)
  }

  // ACTIONS
  // Add a user as an Officer with permissions by NIB
  const addOfficer = async () => {
    const steps = ['1', '2']
    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText: 'Next >',
      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: 'swal2-noanimation' },
      hideClass: { backdrop: 'swal2-noanimation' }
    })
    
    const { value: nib } = await Queue.fire({
      currentProgressStep: 0,
      // optional class to show fade-in backdrop animation which was disabled in Queue mixin
      showClass: { backdrop: 'swal2-noanimation' },
      title: `Add Officer by NIB`,
      input: 'number',
      inputLabel: 'NIB',
      // inputValue: inputValue,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'NIB cannot be empty!'
        }
      }
    })

    const result = await fetchRecords(true, nib)
    if (!result) {
      return Swal.fire(
        'Oops...',
        `No account has been found with the given NIB number ${nib}`,
        'error'
        )
    }

    // console.log(result)

    const { value: permission } = await Queue.fire({
      currentProgressStep: 1,
      showClass: { backdrop: 'swal2-noanimation' },
      icon: 'info',
      title: 'Select Officer Permissions',
      html: `
        <b>Officer Details</b>:<br>
        <span style='text-transform: capitalize;'><b>Name</b>: ${result.first_name} ${result.last_name}</span><br>
        <span style='text-transform: capitalize;'><b>NIB #</b>: ${nib}</span><br>
        <span><b>Email</b>: ${result.email}</span><br>
        `,
      input: "select",
      inputOptions: availableRoles,
      inputPlaceholder: "Select a permission level",
      showCancelButton: true,
    })

    if (!nib || !permission) return

    try {
      await $app.axios.put('role_management/change_permissions', {
          officer_id: result.id,
          permissions: permission,
          ministry: ministry
      })

      toastr.success('Success', 'Officer Permissions successfully updated')
      setRefresh(true)
    } catch (error) {
      setLoading(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing your request',
        'error'
        )
      }
      
    return
  }

  const changePermissions = selector => async () => {
    // console.log('Selector', selector);
    // console.log('Selected', selected);

    // Single Record Update
    if (selector) {
      const { isConfirmed, value: permission } = await Swal.fire({
        icon: 'info',
        title: 'Select Officer Permission',
        html: `
          Are you sure you want to change officer permissions for
          <strong>${`${selector.first_name} ${selector.last_name}`.capitalize()}</strong>?`,
        input: "select",
        inputOptions: availableRoles,
        inputPlaceholder: "Select a permission level",
        showCancelButton: true,
      })

      if (!isConfirmed) return
      setLoading(true)

      try {
        await $app.axios.put('role_management/change_permissions', {
            officer_id: selector.id,
            permissions: permission,
            ministry: ministry
        })

        toastr.success('Success', 'Officer Permissions successfully removed')
        fetchRecords()
      } catch (error) {
        setLoading(false)
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your request',
          'error'
          )
        }
        
      return
    }
  }

  const removePermissions = selector => async () => {
    // console.log('Selector', selector);
    // console.log('Selected', selected);

    // Single Record Update
    if (selector && !Array.isArray(selector)) {
      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: 'Remove All Permisions?',
        html: `
          Are you sure you want to remove all officer permissions for
          <strong>${`${selector.first_name} ${selector.last_name}`.capitalize()}</strong>?
        `,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
      })

      if (!isConfirmed) return
      setLoading(true)

      try {
        await $app.axios.put('role_management/remove_permissions', {
            officer_ids: [selector.id],
        })

        toastr.success('Success', 'Officer Permissions successfully removed')
        fetchRecords()
      } catch (error) {
        setLoading(false)
        console.error(error)
        Swal.fire(
          'Oops...',
          'There has been an error with processing your request',
          'error'
          )
        }
        
      return
    }

    // Batch Updates
    const affectingAll = selector === listing
    const { isDismissed } = await Swal.fire({
      icon: 'question',
      title: `Remove Permissions for ${affectingAll ? 'All' : 'Selected'} Officers?`,
      html: affectingAll
        ? `
          Are you sure that you want to remove permissions for all
          of the officers on the current page?
        `
        : `
          Are you sure you want to remove permissions for
          the ${selected.length} selected officer(s)?
        `,
      showCancelButton: true,
      confirmButtonText: 'Yes, Remove All',
    })

    if (isDismissed) return
    setLoading(true)

    try {
      const officer_ids = selector.map(o => o.id)

      await $app.axios.put('role_management/remove_permissions', { officer_ids: officer_ids })

      toastr.success('Success', 'Officer Permissions successfully removed')
      fetchRecords()
    } catch (error) {
      setLoading(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing your request',
        'error'
      )
    }
  }

  // COLUMNS FOR DATATABLE
  const columns = [
    {
      name: 'Officer',
      selector: 'officer.name',
      sortable: true,
      width: '300px',
      cell: r => {
          const fName = r.first_name
          const lName = r.last_name

        return (
          <span>
            {`${lName}, ${fName}`.capitalize()}
          </span>
        )
      },
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      width: '400px',
    },
    {
      name: 'Permissions',
      // omit: service?.key != 'SYMBOL_USE_PERMISSION',
      selector: row => row.roles.join(', '),
      sortable: true,
      width: '400px',
    },
    {
      name: 'Actions',
      right: true,
      cell: row => (
        <Menu
          menuButton={<span data-action>Actions</span>}
          transition
          portal
          arrow
        >
          <MenuItem onClick={changePermissions(row)}>
            Change Permissions
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={removePermissions(row)}>
            Remove All Permissions
          </MenuItem>
        </Menu>
      ),
    }
  ]

  return (
    <Fragment>
      <section className='content minister-applications-table'>
        <Loader loading={loading} />
        <article className='page-inner p-4'>
          <header className='flex justify-between items-center mb-6'>
            <h1 className='text-4xl text-gray-800'>
              Officer Permission Management
            </h1>
          </header>
          <div className='card'>
            <div className='card-header flex flex-col xl:flex-row justify-between items-center'>
              <label className='flex items-center space-x-2'>
                <span className='font-semibold'>Service</span>
                <select
                  name='service'
                  value={ministry?.key}
                  onChange={(ev) => changeMinistry(ev.target.value)}
                  className='form-control'
                >
                  <option value=''>Select A Ministry</option>
                  {ministryOptions?.map(({ key, label }) => (
                    <option key={key} value={key} children={label} />
                  ))}
                </select>
              </label>
              {ministry && (
                <aside className='flex space-x-4 actions'>
                  <span onClick={() => addOfficer()}>
                    Add Officer
                  </span>
                </aside>
              )}
              <aside className='flex space-x-4 actions'>
                <span onClick={() => setRefresh(true)}>
                  Refresh
                </span>
                <span onClick={removePermissions(selected)}>
                  Remove Permissions ({selected.length ? 'Selected' : 'None'})
                </span>
              </aside>
            </div>
            <div className='card-body'>
              {ministry && (
                <div className='table-responsive form-records-table'>
                  <DataTable
                    data={listing}
                    columns={columns}
                    onSelectedRowsChange={s => setSelected(s.selectedRows)}
                    clearSelectedRows={clear}
                    noHeader
                    selectableRows
                    pagination
                    paginationServer
                    paginationPerPage={params.per_page}
                    paginationTotalRows={params.no_of_records}
                    onChangeRowsPerPage={per_page => setParams({ per_page })}
                    onChangePage={page => setParams({ page })}
                  />
                </div>
              )}

              {(!ministry) && (
                <div className='py-10 text-center font-semibold'>
                  Please select a Ministry
                </div>
              )}
            </div>
          </div>
        </article>
      </section>
    </Fragment>
  )
}

export { RoleManagement }
