import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import passportPhotoExample from '@helpers/passport-photo-example'

export default class JobSkill extends React.Component {
  resource = '/job_skills'
  form_key = 'job_skill'
  defaults = {}

  // Delete lines as needed
  // fee           = 99
  free          = true
  postpaid      = false
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  childProofLabel = (form) => {
    if (form.choose_upload == 'Birth Certificate') {
      return 'Registered birth certificate of minor'
    }
    else if (form.choose_upload == 'Adoption Certificate') {
      return 'Certified Adoption Certificate'
    }
    else if (form.choose_upload == 'Certified Court Order') {
      return 'Certified Court Order for proof of legal Guardianship'
    }
    else if (form.choose_upload == 'Letter from Social Services') {
      return 'Valid letter from Department of Social Services, where the minor is under state care'
    } else {
      return 'Child Identification Documents'
    }
  }

  nationalIDOptions = (form) => {
    const modifyList = () => {
      let index = 0;
      switch (form.id_upload_type_one || form.id_upload_type_two) {
        case 'Birth Certificate':
          index = options.findIndex(e => e == 'Birth Certificate')
          options = options.toSpliced(index, 1)
          break;
        case 'Passport':
          index = options.findIndex(e => e == 'Passport')
          options = options.toSpliced(index, 1)
          break;
        case 'Voters Card':
          index = options.findIndex(e => e == 'Voters Card')
          options = options.toSpliced(index, 1)
          break;
        case 'NIB Card':
          index = options.findIndex(e => e == 'NIB Card')
          options = options.toSpliced(index, 1)
          break;
        default:
         return options
      }
    }
    let options = ['Birth Certificate', 'Passport', 'Voters Card', 'NIB Card'];
    if (form.i_am_applicant) {
      options = ['Birth Certificate', 'Passport', 'Voters Card'];
      modifyList();
    } else {
      modifyList()
    }

    return options
  }

  positionOfInterest = (interest) => {
    switch (interest) {
      case 'Marine Transportation & Shipping':
        return [
          { label: 'Deck Officer (Mate)', value: 'Deck Officer (Mate)'},
          { label: 'Ship Captain', value: 'Ship Captain'},
          { label: 'Marine Engineer', value: 'Marine Engineer'},
          { label: 'Navigator', value: 'Navigator'},
          { label: 'Cargo Officer', value: 'Cargo Officer'},
          { label: 'Port Operations Manager', value: 'Port Operations Manager'},
          { label: 'Harbor Pilot', value: 'Harbor Pilot'},
          { label: "Steward (Ship's Cook/Hotel Services)", value: "Steward (Ship's Cook/Hotel Services)"},
          { label: 'Shipping Agent', value: 'Shipping Agent'},
          { label: 'Logistics Coordinator', value: 'Logistics Coordinator'},
          { label: 'Freight Forwarder', value: 'Freight Forwarder'},
        ]
        
      case 'Maritime Engineering & Maintenance':
        return [
          { label: 'Marine Mechanical Technician', value: 'Marine Mechanical Technician'},
          { label: 'Marine Electrical Technician', value: 'Marine Electrical Technician'},
          { label: 'Shipbuilding Engineer', value: 'Shipbuilding Engineer'},
          { label: 'Shipyard Worker/Ship Repair Technician', value: 'Shipyard Worker/Ship Repair Technician'},
          { label: 'Naval Architect', value: 'Naval Architect'},
          { label: 'Hydrographic Surveyor', value: 'Hydrographic Surveyor'},
          { label: 'Marine Surveyor', value: 'Marine Surveyor'},
        ]
        
      case 'Marine Tourism & Recreation':
        return [
          { label: 'Cruise Ship Staff (Various Roles)', value: 'Cruise Ship Staff (Various Roles)'},
          { label: 'Scuba Diving Instructor', value: 'Scuba Diving Instructor'},
          { label: 'Marine Safety Officer', value: 'Marine Safety Officer'},
          { label: 'Boat Captain (for tourism)', value: 'Boat Captain (for tourism)'},
          { label: 'Charter Boat Crew', value: 'Charter Boat Crew'},
          { label: 'Marine Eco-tourism Guide', value: 'Marine Eco-tourism Guide'},
        ]
        
      case 'Fisheries & Marine Conservation':
        return [
          { label: 'Fisherman/Commercial Fisher', value: 'Fisherman/Commercial Fisher'},
          { label: 'Aquaculture Technician', value: 'Aquaculture Technician'},
          { label: 'Marine Biologist', value: 'Marine Biologist'},
          { label: 'Marine Conservation Specialist', value: 'Marine Conservation Specialist'},
          { label: 'Fishery Manager', value: 'Fishery Manager'},
          { label: 'Marine Mammal Specialist', value: 'Marine Mammal Specialist'},
        ]
        
      case 'Maritime Safety & Security':
        return [
          { label: 'Coast Guard Officer', value: 'Coast Guard Officer'},
          { label: 'Maritime Security Consultant', value: 'Maritime Security Consultant'},
          { label: 'Marine Safety Trainer', value: 'Marine Safety Trainer'},
          { label: 'Vessel Security Officer', value: 'Vessel Security Officer'},
          { label: 'Port Security Officer', value: 'Port Security Officer'},
        ]
        
      case 'Environmental and Regulatory Roles':
        return [
          { label: 'Marine Environmental Officer', value: 'Marine Environmental Officer'},
          { label: 'Environmental Consultant (Marine Focus)', value: 'Environmental Consultant (Marine Focus)'},
          { label: 'Marine Policy Analyst', value: 'Marine Policy Analyst'},
          { label: 'Marine Pollution Control Officer', value: 'Marine Pollution Control Officer'},
          { label: 'Coastal Zone Manager', value: 'Coastal Zone Manager'},
        ]
        
      case 'Maritime Education & Training':
        return [
          { label: 'Maritime Trainer/Instructor', value: 'Maritime Trainer/Instructor'},
          { label: 'Maritime Simulation Specialist', value: 'Maritime Simulation Specialist'},
          { label: 'Maritime Researcher', value: 'Maritime Researcher'},
          { label: 'Maritime Law Specialist', value: 'Maritime Law Specialist'},
        ]
        
      case 'Maritime Administration & Business':
        return [
          { label: 'Port Manager', value: 'Port Manager'},
          { label: 'Shipping Broker', value: 'Shipping Broker'},
          { label: 'Maritime Operations Manager', value: 'Maritime Operations Manager'},
          { label: 'Customs Officer (Maritime)', value: 'Customs Officer (Maritime)'},
          { label: 'Marine Insurance Specialist', value: 'Marine Insurance Specialist'},
          { label: 'International Trade Specialist (Maritime Focus)', value: 'International Trade Specialist (Maritime Focus)'},
        ]
        
      case 'Technological & Innovative Roles in Maritime':
        return [
          { label: 'Marine Robotics Technician', value: 'Marine Robotics Technician'},
          { label: 'Maritime Data Analyst', value: 'Maritime Data Analyst'},
          { label: 'Ship Technology Innovator', value: 'Ship Technology Innovator'},
          { label: 'Autonomous Vessel Specialist', value: 'Autonomous Vessel Specialist'},
        ]
        
      case 'Maritime Marketing & Sales':
        return [
          { label: 'Maritime Sales Manager', value: 'Maritime Sales Manager'},
          { label: 'Cruise Sales and Marketing Specialist', value: 'Cruise Sales and Marketing Specialist'},
          { label: 'Maritime Event Planner', value: 'Maritime Event Planner'},
        ]
        
      default:
        return [];
    }
  }

  interestDesc = (interest) => {
    switch (interest) {
      // Marine Transportation & Shipping
      case 'Deck Officer (Mate)':
        return "Officers responsible for navigation, cargo handling, and safety aboard ships."
        
      case 'Ship Captain':
        return "The commanding officer of a ship, responsible for the operation and safety of the vessel."
        
      case 'Marine Engineer':
        return "Responsible for maintaining and operating the mechanical systems of ships."
        
      case 'Navigator':
        return "Specializes in navigating vessels across oceans and waterways using charts, GPS, and other navigational tools."
        
      case 'Cargo Officer':
        return "Manages cargo loading, unloading, and securing, ensuring safety and compliance with regulations."
        
      case 'Port Operations Manager':
        return "Oversees the operations of a port, including docking, loading/unloading, and scheduling."
        
      case 'Harbor Pilot':
        return "A professional who guides ships in and out of harbors and through narrow or dangerous waters."
        
      case "Steward (Ship's Cook/Hotel Services)":
        return "Responsible for food preparation, hospitality services, and the general well-being of passengers and crew."
        
      case 'Shipping Agent':
        return "Coordinates and manages the logistics, documentation, and regulatory compliance for shipping operations."
        
      case 'Logistics Coordinator':
        return "Plans and oversees the movement of goods by sea, ensuring timely deliveries."
        
      case 'Freight Forwarder':
        return "Specializes in arranging the transport of cargo and ensuring it complies with international shipping laws."
        

      // Maritime Engineering & Maintenance
      case 'Marine Mechanical Technician':
        return "Specializes in maintaining and repairing ship engines, propulsion systems, and mechanical equipment."
        
      case 'Marine Electrical Technician':
        return "Works on the electrical systems of ships, including power generation and navigation equipment."
        
      case 'Shipbuilding Engineer':
        return "Involved in the design, construction, and repair of ships and maritime vessels."
        
      case 'Shipyard Worker/Ship Repair Technician':
        return "Works at shipyards performing maintenance, repairs, and refurbishments on ships and boats."
        
      case 'Naval Architect':
        return "Designs and builds ships and boats, ensuring structural integrity and compliance with maritime regulations."
        
      case 'Hydrographic Surveyor':
        return "Specializes in mapping the underwater environment, ensuring safe navigation and port construction."
        
      case 'Marine Surveyor':
        return "Inspects vessels and cargo to ensure they meet safety, environmental, and legal standards."
        

      // Marine Tourism & Recreation
      case 'Cruise Ship Staff (Various Roles)':
        return "Includes positions like tour guides, event coordinators, entertainers, chefs, and hospitality staff aboard cruise ships."
        
      case 'Scuba Diving Instructor':
        return "Provides underwater diving instruction, often on cruise ships, resorts, or for eco-tourism operators."
        
      case 'Marine Safety Officer':
        return "Ensures the safety of passengers and crew aboard recreational vessels and cruise ships."
        
      case 'Boat Captain (for tourism)':
        return "Operates boats for tourism-related activities, such as fishing charters, sightseeing tours, or water sports."
        
      case 'Charter Boat Crew':
        return "Crew members who manage private yacht or boat charters, providing services to tourists and clients."
        
      case 'Marine Eco-tourism Guide':
        return "Leads tourists on marine-based eco-tours, educating them about marine life, ecosystems, and sustainability efforts."
        

      // Fisheries & Marine Conservation
      case 'Fisherman/Commercial Fisher':
        return "Works on fishing vessels to catch fish, lobster, conch, or other marine life for commercial sale."
        
      case 'Aquaculture Technician':
        return "Specializes in farming fish, shellfish, or seaweed for commercial purposes, ensuring proper cultivation and harvesting."
        
      case 'Marine Biologist':
        return "Studies marine life and ecosystems, working in research, conservation, and management."
        
      case 'Marine Conservation Specialist':
        return "Focuses on protecting marine environments and species through research, policy advocacy, and community outreach."
        
      case 'Fishery Manager':
        return "Manages fish stocks, setting quotas, and ensuring sustainable fishing practices are followed."
        
      case 'Marine Mammal Specialist':
        return "Studies and conserves marine mammals like whales, dolphins, and seals, often working with research institutions or conservation organizations."
        

      // Maritime Safety & Security
      case 'Coast Guard Officer':
        return "Enforces maritime law, conducts search and rescue operations, and ensures national maritime security."
        
      case 'Maritime Security Consultant':
        return "Advises on the safety and security of shipping routes, ports, and vessels, often related to piracy and terrorism prevention."
        
      case 'Marine Safety Trainer':
        return "Provides training on maritime safety protocols, such as firefighting, first aid, and life-saving techniques."
        
      case 'Vessel Security Officer':
        return "Ensures the safety of vessels and their crew, including managing onboard security threats."
        
      case 'Port Security Officer':
        return "Monitors and ensures the safety of goods, vessels, and people within port areas."
        

      // Environmental and Regulatory Roles
      case 'Marine Environmental Officer':
        return "Monitors pollution, implements regulations, and enforces environmental standards in marine areas."
        
      case 'Environmental Consultant (Marine Focus)':
        return "Advises on minimizing the environmental impact of maritime activities, from shipping to coastal development."
        
      case 'Marine Policy Analyst':
        return "Researches and analyzes maritime laws, regulations, and policies, often working with governments or NGOs."
        
      case 'Marine Pollution Control Officer':
        return "Works to prevent and manage marine pollution, including oil spills and plastic waste."
        
      case 'Coastal Zone Manager':
        return "Manages the development, conservation, and restoration of coastal areas to ensure sustainable use of marine resources."
        

      // Maritime Education & Training
      case 'Maritime Trainer/Instructor':
        return "Teaches maritime skills, including navigation, safety, and engineering, at technical schools or maritime academies."
        
      case 'Maritime Simulation Specialist':
        return "Operates and maintains simulators for training mariners in navigation, ship handling, and emergency procedures."
        
      case 'Maritime Researcher':
        return "Conducts academic or applied research on maritime topics, including new technologies, environmental practices, and shipping innovations."
        
      case 'Maritime Law Specialist':
        return "Specializes in maritime law, focusing on issues such as shipping regulations, maritime insurance, and environmental laws."
        

      // Maritime Administration & Business
      case 'Port Manager':
        return "Oversees the day-to-day operations of a port, including logistics, staff, and maintenance."
        
      case 'Shipping Broker':
        return "Acts as an intermediary between cargo owners and shipping companies to arrange transport."
        
      case 'Maritime Operations Manager':
        return "Manages the operational side of maritime transportation companies, ensuring efficient and cost-effective service."
        
      case 'Customs Officer (Maritime)':
        return "Monitors and enforces the import and export regulations for goods arriving by sea."
        
      case 'Marine Insurance Specialist':
        return "Deals with the insurance of ships, cargo, and related maritime risks."
        
      case 'International Trade Specialist (Maritime Focus)':
        return "Focuses on the global movement of goods by sea, managing trade agreements and logistics."
        

      // Technological & Innovative Roles in Maritime
      case 'Marine Robotics Technician':
        return "Works with underwater drones and autonomous vessels used for research, exploration, or surveillance."
        
      case 'Maritime Data Analyst':
        return "Analyzes data from shipping routes, port activity, and maritime trends to optimize operations and safety."
        
      case 'Ship Technology Innovator':
        return "Develops new technologies related to shipbuilding, navigation, and environmental protection."
        
      case 'Autonomous Vessel Specialist':
        return "Works with autonomous or unmanned ships, ensuring safety and operational efficiency."
        

      // Maritime Marketing & Sales
      case 'Maritime Sales Manager':
        return "Sells maritime products and services, such as shipping vessels, safety equipment, or logistics software."
        
      case 'Cruise Sales and Marketing Specialist':
        return "Promotes cruise packages and services to tourists, working with travel agents and tourism boards."
        
      case 'Maritime Event Planner':
        return "Organizes maritime industry events, conferences, and exhibitions for networking and knowledge-sharing."
        
      default:
        return ""
    }
  }

  fields = form => [
    '::Applicant Details',
    {
      name: 'i_am_applicant',
      type: 'select:bool',
      label: 'I am applying for myself',
    },
    {
      name: 'app_first_name',
      label: 'First Name',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_middle_name',
      label: 'Middle Name',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'app_last_name',
      label: 'Last Name',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_date_of_birth',
      label: 'Date of Birth',
      type: 'date',
      showYearDropdown: true,
      hide: form.i_am_applicant,
    },
    {
      name: 'app_age',
      label: 'Age',
      type: 'integer',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_sex',
      label: 'Sex',
      options: ['male', 'female'],
      hide: form.i_am_applicant,
    },
    {
      name: 'app_nib_number',
      label: 'National Insurance number',
      type: 'integer',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_telephone_numb',
      label: 'Telephone Number',
      type: 'phone',
      hide: form.i_am_applicant,
    },
    {
      name: 'app_email_address',
      label: 'Email Address',
      type: 'email',
      hide: form.i_am_applicant,
    },
    {
      name: 'emergency_contact_list',
      label: 'Emergency Contact',
      rowLabel: 'Emergency Contact',
      required: false,
      fields: [
        {
          name: 'first_name',
          // label: 'First name of parent/guardian'
        },
        {
          name: 'last_name',
          // label: 'Last name of parent/guardian'
        },
        {
          name: 'relationship_to_youth',
          label: 'Relation to applicant',
          options: ['parent', 'guardian', 'relative', 'friend', 'other'],
        },
        {
          name: 'contact_number',
          // label: 'Parent/Guardian phone contact',
          type: 'phone'
        },
        {
          name: 'alt_phone_contact',
          label: 'Alternate phone contact',
          type: 'phone',
          required: false,
        }
      ]
    },
    "::SPACER",
    "::Area of Interest",
    {
      name: 'area_of_interest',
      label: 'Area of Interest',
      options: [
        { label: 'Marine Transportation & Shipping', value: 'Marine Transportation & Shipping'},
        { label: 'Maritime Engineering & Maintenance', value: 'Maritime Engineering & Maintenance'},
        { label: 'Marine Tourism & Recreation', value: 'Marine Tourism & Recreation'},
        { label: 'Fisheries & Marine Conservation', value: 'Fisheries & Marine Conservation'},
        { label: 'Maritime Safety & Security', value: 'Maritime Safety & Security'},
        { label: 'Environmental and Regulatory Roles', value: 'Environmental and Regulatory Roles'},
        { label: 'Maritime Education & Training', value: 'Maritime Education & Training'},
        { label: 'Maritime Administration & Business', value: 'Maritime Administration & Business'},
        { label: 'Technological & Innovative Roles in Maritime', value: 'Technological & Innovative Roles in Maritime'},
        { label: 'Maritime Marketing & Sales', value: 'Maritime Marketing & Sales'},
      ],
      callback: async () => {
        if (form.area_of_interest) {
          return { sub_area_of_interest: '' }
        }
      },
    },
    {
      name: 'sub_area_of_interest',
      label: 'Position of Interest',
      options: this.positionOfInterest(form.area_of_interest),
      hint: this.interestDesc(form.sub_area_of_interest),
    },
    // {
    //   name: 'sub_interest_desc',
    //   label: 'Description of Position of Interest',
    //   hint: this.interestDesc(form.sub_area_of_interest),
    //   save: false,
    //   disabled: true,
    // },

    "::SPACER",
    "::Education Information",
    {
      name: 'school_list',
      label: 'Tell us the last High School you attended',
      columns: [
       {
        name: 'school_name',
       },
       {
        name: 'school_address',
       },
       {
        name: 'from_year',
        type: 'integer',
       } ,
       {
        name: 'to_year',
        type: 'integer'
      },
      ]
    },
    {
      name: 'tertiary_school_list',
      label: 'Tell us the last Tertiary School you attended',
      hint: 'Tertiary education refers to all formal post-secondary education, including public and private universities, colleges, technical training institutes, and vocational schools.',
      columns: [
       {
        name: 'school_name',
       },
       {
        name: 'school_address',
       },
       {
        name: 'from_year',
        type: 'integer',
       } ,
       {
        name: 'to_year',
        type: 'integer'
      },
      ]
    },
    {
      name: 'accomplishments_list',
      label: 'Awards and Certificates',
      hint: 'List any awards, achievements or certificates',
      columns: ['accomplishment'],
      format: v => v.accomplishment,
    },

    "::References",
    {
      name:'references_list',
      label:'Give the name of persons, who are not immediate relatives, to provide a reference on your behalf',
      rowLabel: 'Reference',
      min: 3,
      // hide: form.which_program != 'corporate placement',
      fields: [
        'first_name',
        'last_name',
        {
          name: 'relationship_to_applicant',
          label: 'Relation to applicant',
          options: ['friend', 'employer', 'other'],
        },
        {
          name: 'occupation'
        },
        {
          name: 'contact_information',
          type: 'phone'
        },
        {
          name: 'alternate_contact_information',
          type: 'phone',
          required: false,
        },
      ],
    },
    {
      name: 'social_media_list',
      label: 'Social Media Handle Links',
      required: false,
      columns: [
        {
          name: 'platform',
          datalist: [
            'Facebook',
            'Twitter',
            'Instagram',
            'TikTok',
            'YouTube',
            'LinkedIn',
            'Pinterest'
          ]
        },
        {
          name: 'tag',
        },
        {
          name: 'tag_link',
          label: 'Tag in Active URL link',
          hint: 'Please include tag within an active URL link'
        },
      ],
    },
    {
      name: 'informed_by',
      label: 'How did you hear about The Bahamas Apprenticeship Program?',
      hint: 'Select all that apply',
      multi: true,
      options: ['Radio', 'Newspaper','Friends','Social Media', 'School', 'Television', 'Extra-curricular program', 'Other'],
    },
    {
      name: 'acceptance_reason',
      label: 'Why should you be accepted into this program?',
      type: 'textarea',
      max: 200,
      maxLength: 200,
    },

    "::Uploads",
    {
      name: 'choose_upload',
      label: "Please upload one of the following documents to verify you are the applicant's parent or guardian",
      options: ['Birth Certificate', 'Adoption Certificate', 'Certified Court Order', 'Letter from Social Services'],
      hide: form.i_am_applicant || form.app_age > 18,
    },
    {
      name: 'child_proof_upload',
      label: this.childProofLabel(form),
      hint: `If the document is not uploaded, you must visit the Division of 
        <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
      key: 'image.0',
      type: 'file:all',
      hide: form.i_am_applicant || form.app_age > 18,
      required: false,
    },
    {
      name: 'guardian_proof_upload',
      label: 'Identification documents of parent or legal guardian',
      hint: `If the document is not uploaded, you must visit the Division of 
      <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
      key: 'image.1',
      type: 'file:all',
      hide: form.i_am_applicant || form.app_age > 18 || form.choose_upload != 'Birth Certificate',
      required: false,
    },
    {
      name: 'photo_upload',
      lable: 'Applicant Personal Photo',
      type: 'file:image',
      key: 'image.2',
      required: true,
    },
    '::Upload two (2) National IDs minimum',
    {
      name: 'id_upload_type_one',
      label: "Please upload one of the following National ID documents",
      hint: 'Do not upload two of the same ID types',
      options: this.nationalIDOptions(form),
    },
    {
      name: 'id_upload_type_two',
      label: "Please upload one of the following National ID documents",
      hint: 'Do not upload two of the same ID types',
      options: this.nationalIDOptions(form),
    },
    {
      name: 'national_id_checker',
      label: 'You cannot use two of the same National ID documents!',
      hint: 'You cannot use two of the same National ID documents!',
      hide: form.id_upload_type_one != form.id_upload_type_two,
      disabled: true,
      save: false,
      view: false,
    },
    {
      name: 'passport_upload',
      // label: 'Passport',
      type: 'file:all',
      key: 'image.3',
      tooltip: 'Only the information page is required',
      hint: passportPhotoExample,
      hide: (form.id_upload_type_one != 'Passport' && form.id_upload_type_two != 'Passport'),
    },
    {
      name: 'voter_card_upload',
      lable: `Voter's Card/Affidavit`,
      type: 'file:all',
      key: 'image.4',
      hide: (form.id_upload_type_one != 'Voters Card' && form.id_upload_type_two != 'Voters Card'),
    },
    {
      name: 'birth_certificate_upload',
      type: 'file:all',
      key: 'image.5',
      hide: (form.id_upload_type_one != 'Birth Certificate' && form.id_upload_type_two != 'Birth Certificate'),
    },
    // {
    //   name: 'certifcate_of_identity_upload',
    //   type: 'file:all',
    //   key: 'image.3',
    //   required: false,
    // },
    {
      name: 'nib_card_upload',
      label:' NIB card',
      type: 'file:all',
      key: 'image.6',
      hide: (form.id_upload_type_one != 'NIB Card' && form.id_upload_type_two != 'NIB Card'),
    },
    '::Other Uploads',
    {
      name: 'police_record_upload',
      label: 'Valid Police Record',
      type: 'file:all',
      key: 'image.7',
      required: true,
    },
    {
      name: 'transcripts_upload',
      label: 'Primary, Junior or Senior high transcripts',
      type: 'file:all',
      key: 'image.8',
      required: true,
    },
    {
      name: 'resume_upload',
      label: 'Recent Resume',
      type: 'file:all',
      key: 'image.9',
      required: true,
    },
    {
      name: 'supporting_doc_upload',
      label: 'Supporting Documents',
      type: 'file:all',
      key: 'image.10',
      required: false,
    },

    "::For Non-bahamian citizens",
    {
      name: 'belonger_permit_upload',
      label: `Belonger's permit from the department of immigration`,
      type: 'file:all',
      key: 'image.11',
      required: false,
    },
    {
      name: 'permanent_residency_permit_upload',
      type: 'file:all',
      key: 'image.12',
      required: false,
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}

/*

Following is a list of options for Phase II of NAP, with drop-down menu sub choices.

1. Tourism & Hospitality
Hotel Management
Tour Guide/Adventure Guide
Event Planning and Management
Customer Service & Front Desk Operations
Culinary Arts & Food Production
Bartending and Mixology
Housekeeping and Facility Maintenance
Tourism Marketing and Sales
Marine Tourism/Boating (e.g., dive instructors, fishing guides, boat maintenance)
Lifeguard services
2. Construction & Engineering
Carpentry & Joinery
Plumbing & Pipefitting
Electrical Installation and Maintenance
Welding and Metal Fabrication
Masonry and Concrete Work
HVAC (Heating, Ventilation, and Air Conditioning)
Construction Project Management
Architectural Drafting and Design
Landscape Architecture and Urban Planning
Structural Engineering Technician
Surveying and Land Development
3. Information Technology & Digital Services
Software Development and Coding
Web Development and Design
Network Administration
Cybersecurity
Data Analysis and Database Management
Digital Marketing and Social Media Management
IT Support and Helpdesk
Graphic Design & Multimedia
App Development
Game Development
4. Marine & Environmental Science
Marine Biology and Oceanography
Fisheries Management
Marine Engineering
Environmental Conservation
Sustainable Agriculture and Aquaculture
Eco-tourism & Sustainability Consulting
Boat Maintenance and Repair
Climate Change & Coastal Management
5. Agriculture & Food Production
Agricultural Technician
Aquaponics and Hydroponics Farming
Livestock Management
Horticulture & Floriculture
Agro-processing (food manufacturing)
Farming Equipment Repair and Maintenance
Crop Production & Soil Management
Agricultural Business Management
6. Healthcare & Social Services
Nursing and Caregiving
Pharmacy Technician
Medical Laboratory Technician
Dental Assistant
Physiotherapy and Occupational Therapy
Emergency Medical Technician (EMT)
Mental Health Counseling
Public Health Educator
Health and Safety Officer
7. Renewable Energy & Sustainability
Solar Panel Installation and Maintenance
Wind Energy Technician
Energy Efficiency Consultant
Battery Storage Technician
Environmental Engineering Technician
Water Conservation Specialist
Sustainable Building & Green Construction
Recycling and Waste Management Technician
8. Transportation & Logistics
Automobile Mechanics
Marine Transport and Shipping
Aircraft Maintenance
Supply Chain Management
Logistics & Warehouse Operations
Truck Driving
Public Transport Operations
Freight Forwarding and Customs Brokerage
Port Operations and Management
9. Creative Arts & Media
Film Production and Cinematography
Photography and Videography
Music Production and Audio Engineering
Fashion Design and Merchandising
Interior Design
Advertising & Public Relations
Journalism and Broadcasting
Content Creation & YouTube/Vlogging
Writing and Copywriting
10. Business, Finance & Administration
Accounting and Bookkeeping
Financial Planning and Analysis
Human Resources & Talent Management
Business Administration
Entrepreneurship & Small Business Development
Banking and Financial Services
Real Estate and Property Management
Insurance and Risk Management
Customer Relationship Management (CRM)
Sales and Marketing
11. Public Safety & Security
Police and Law Enforcement
Firefighting and Rescue Services
Security Systems Installation and Monitoring
Disaster Preparedness & Emergency Management
Search and Rescue Operations
Correctional Facility Operations
Private Security & Surveillance
Traffic Management and Road Safety
12. Education & Training
Early Childhood Education
Secondary School Teaching (various subjects)
Special Education
Technical and Vocational Education and Training (TVET) Instructor
Adult Education and Lifelong Learning
Instructional Design and Curriculum Development
Education Administration
13. Legal & Civic Services
Paralegal and Legal Assistant
Court Reporting and Transcription
Community Outreach & Legal Aid
Notary Public Services
Public Policy and Governance
Criminal Justice and Law Enforcement Consulting

*/