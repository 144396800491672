import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import { OPENMAPFIELDS } from '@modules/open-street-map-field'
export default class BuildingPermit extends React.Component {
  resource = '/building_regulations_acts'
  form_key = 'building_regulations_act'

  coordinates = null

  // isWalls = /walls/.test(this.props.location.pathname)



  // decideConstructionType = () => {
  //   if (this.isWalls) {
  //     return 'walls'
  //   } else if (this.isResidence) {
  //     return 'residence'
  //   } else {
  //     return 'renovations'
  //   }
  // }

  // defaults = {
  //   construction_type_list: this.decideConstructionType(),
  // }

  // read_params = {
  //   admin: true,
  //   label: ' ',
  //   default: 'Default',
  //   values: {
  //     'Default': {
  //       division_selected: this.defaults.division_selected
  //     },
  //   },
  // }
  
  // Delete lines as needed
  // fee = 99
  free = true
  postpaid = false
  skip_location = true
  hide_buttons = false
  custom_actions = []

  // Allow map component selected coordinates to translate to form latitude and longitude fields
  validate = async values => {
    const update = {}

    if(this.coordinates) {
      update.latitude = this.coordinates[0]
      update.longitude = this.coordinates[1]
    }

    return update
  }


  foremost = null

  fields = form => [
    {
      name: 'construction_type_list',
      lable: 'Building Permit Type',
      options: ['Apartments', 'Building Additions', 'Commercial Buildings', 'Pool', 'Renovations',
        'Residence', 'Walls'
      ]
      // save: true,
      // hide: true,
    },
    {
      name: 'island',
      options: islands,
    },
    {
      name: 'building_square_ft',
      label: 'Building Square Feet',
      type: 'integer',
    },
    {
      name: 'property_type',
      label: 'Property Type',
      options: ['Residential', 'Commercial']
    },
    {
      name: 'building_permit_numb',
      label: 'Buidling Permit Number',
    },
    // {
    //   name: 'area',
    // },
    // {
    //   name: 'drawings_numb',
    //   label: 'Number of drawings',
    //   type: 'integer',
    // },
    {
      name: 'i_am_delegate',
      label: 'I am a delegate',
      type: 'select:bool',
    },
    {
      name: 'delegate_profession',
      label: 'Profession',
    },

    '::Applicant information',
    {
      name: 'applicant_first_name',
      label: 'First Name',
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_middle_name',
      label: 'Middle Name',
      hide: !form.i_am_delegate,
      required: false,
    },
    {
      name: 'applicant_last_name',
      label: 'Last Name',
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_street_address',
      label: 'Address',
      type: 'textarea',
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_island',
      label: 'Island',
      options: islands,
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_phone',
      label: 'Phone Number',
      type: 'phone',
      hide: !form.i_am_delegate,
    },
    {
      name: 'applicant_email',
      label: 'Email Address',
      type: 'email',
      hide: !form.i_am_delegate,
    },
    {
      name: 'building_contractor',
    },

    '::Interest in Land',
    {
      name: 'interest_in_land',
      label: 'What is the intended use of this land?',
      type: 'textarea',
    },
    {
      name: 'development_consent',
      label:
        'Has consent been given to begin development on this land/building?',
      type: 'select:bool',
    },
    {
      name: 'land_address',
      label: 'Address of Land',
      type: 'textarea',
      hint: 'Be very decriptive',
    },
    {
      name: 'land_lot_number',
      label: 'Lot number',
      required: false,
    },
    {
      name: 'land_building_number',
      label: 'Building number',
      required: false,
    },
    {
      name: 'land_subdivision',
      label: 'Subdivision',
    },
    // {
    //   name: 'land_longitude',
    //   label: 'Longitude',
    // },
    // {
    //   name: 'land_latitude',
    //   label: 'Latitude',
    // },
    ...OPENMAPFIELDS(this),

    '::Proposed Development Details',
    // {
    //   name: 'b_c',
    //   label: 'B.C.',
    //   type: 'select:bool',
    //   required: false,
    // },
    // {
    //   name: 'w_c',
    //   label: 'W.C.',
    //   type: 'select:bool',
    //   required: false,
    // },
    {
      name: 'proposed_development_details',
      label: 'How do you intend to use the land/building?',
      type: 'textarea',
      hint: 'If you intend to use it for more than one purpose, explain',
    },
    {
      name: 'current_land_use_desc',
      label: 'What is the land/building currently being used for?',
      type: 'textarea',
    },

    '::Materials For Exterior Finish of the Buildings',
    {
      name: 'walls_materials_list',
      label: 'List all materials needed for the walls.',
      columns: ['wall'],
      format: v => v.wall,
    },
    {
      name: 'roofing_materials_list',
      label: 'List all materials needed for the roof.',
      columns: ['roof'],
      format: v => v.roof,
    },
    {
      name: 'total_area',
      label: 'What is the total area?',
      type: 'integer',
      hint: 'In square feet',
    },
    {
      name: 'estimated_cost',
      label: 'What is the estimated cost',
      is: 'currency',
    },

    '::Architect Details',
    {
      name: 'architect_first_name',
      label: 'First Name',
    },
    {
      name: 'architect_middle_name',
      label: 'Middle Name',
      required: false,
    },
    {
      name: 'architect_last_name',
      label: 'Last Name',
    },
    {
      name: 'architect_island',
      label: 'Island',
      options: islands,
    },
    {
      name: 'architect_street_address',
      label: 'Address',
    },
    {
      name: 'architect_phone',
      label: 'Phone Number',
      type: 'phone',
    },
    {
      name: 'architect_email',
      label: 'Email Address',
    },

    '::Surveyor Details',
    {
      name: 'surveyor_first_name',
      label: 'First Name',
    },
    {
      name: 'surveyor_middle_name',
      label: 'Middle Name',
      required: false,
    },
    {
      name: 'surveyor_last_name',
      label: 'Last Name',
    },
    {
      name: 'surveyor_island',
      label: 'Island',
      options: islands,
    },
    {
      name: 'surveyor_address',
      label: 'Address',
    },
    {
      name: 'surveyor_phone',
      label: 'Phone Number',
      type: 'phone',
    },
    {
      name: 'surveyor_email',
      label: 'Email Address',
    },

    '::Required Documents',
    {
      name: 'location_plan_upload',
      label: 'Location plan',
      key: 'image.1',
      type: 'file:all',
    },
    {
      name: 'survey_upload',
      label: 'Survey',
      key: 'image.2',
      type: 'file:all',
    },
    {
      name: 'site_plans_upload',
      label: 'Site plan',
      key: 'image.3',
      type: 'file:all',
    },
    {
      name: 'architect_drawings_upload',
      label: 'Architect Drawings',
      key: 'image.4',
      type: 'file:all',
    },
    {
      name: 'structural_drawings_upload',
      label: 'Structural Drawings',
      key: 'image.5',
      type: 'file:all',
    },
    {
      name: 'garage_drawings_upload',
      label: 'Garage Drawings',
      key: 'image.6',
      type: 'file:all',
      hide: form.property_type == 'Residential',
      required: false,
    },
    {
      name: 'mechanical_drawings_upload',
      label: 'Mechanical Drawings',
      key: 'image.7',
      type: 'file:all',
    },
    {
      name: 'electrical_drawings_upload',
      label: 'Electrical Drawings',
      key: 'image.8',
      type: 'file:all',
    },
    {
      name: 'plumbing_drawings_upload',
      label: 'Plumbing Drawings',
      key: 'image.9',
      type: 'file:all',
    },
    {
      name: 'volatile_drawings_upload',
      label: 'Volatile Drawings',
      key: 'image.10',
      type: 'file:all',
      hide: form.property_type == 'Residential',
    },
    {
      name: 'elevator_drawings_upload',
      label: 'Elevator Drawings',
      key: 'image.11',
      type: 'file:all',
    },
    {
      name: 'disability_upload',
      label: 'Disability Accessibility (ADA) Compliance',
      key: 'image.12',
      type: 'file:all',
      hide: form.property_type == 'Residential',
      required: false,
    },
    {
      name: 'approval_letter_upload',
      label: 'Approval letter',
      key: 'image.13',
      type: 'file:all',
    },
    {
      name: 'homeowners_upload',
      label: 'Homeowners Association Documents',
      key: 'image.14',
      type: 'file:all',
      required: false,
    },
    {
      name: 'family_island_upload',
      label: 'Family Island Administrator Documents',
      key: 'image.15',
      type: 'file:all',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
