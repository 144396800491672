import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import countries from '@constants/countries'
import nationalities from '@constants/nationalities'

export default class AircraftRegistration extends React.Component {
  resource = '/aircraft_registrations'
  form_key = 'aircraft_registration'
  defaults = {}

  // Delete lines as needed
//   fee           = 99
  free          = true
//   postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    "::Details of Aircraft",
    {
        name: 'regist_mark_previous',
        label:'Registration Mark (Previous)',
    },
    {
        name: 'regist_mark_allocated',
        label:'Registration Mark (Allocated)',
    },
    {
        name: 'aircraft_designation',
        label: `Manufacturer's Designation of Aircraft`,
        type: 'textarea'
    },
    {
        name: 'serial_no',
        label: 'Serial Number'
    },
    {
        name: 'manufacturer',
        label: 'Manufacturer of Aircraft'
    },
    {
        name: 'mtom',
        label: 'MTOM(kg)' 
    },

    "::Details of Aircraft Owner",
    {
        name: 'regist_under_company',
        label: 'Is aircraft registered under a company?',
        type: 'select:bool',
    },
    {
        heading: (<p>. If the aircraft is to be registered under a company, state:</p>),
        hide: !form.regist_under_company,
    },
    {
        heading: (<p> If the aircraft is to be registered under an individual, state:</p>),
        hide: form.regist_under_company,
    },
    {
        name: 'comapny_name',
        label: 'Name of Comapny',
        required: false,
        hide: !form.regist_under_company,
    },
    {
        name: 'regist_address',
        label: 'Registered Address',
        hide: !form.regist_under_company,
    },
    {
        name: 'country',
        label: 'Country of Incorporation',
        options: countries,
        hide: !form.regist_under_company,
    },
    {
        name: 'contact_first_name',
        label: 'Contact First Name',
    },
    {
        name: 'contact_middle_name',
        label: 'Contact Middle Name',
        required: false,
    },
    {
        name: 'contact_last_name',
        label: 'Contact Last Name'
    },
    {
        name: 'contact_phone_numb',
        label: 'Telephone No.',
        type: 'phone',
    },
    {
        name: 'regist_num',
        label: 'Registration No.'
    },
    {
        name: 'contact_email',
        type: 'email',
    },
    {
        name: 'contact_fax_num',
        label: 'Fax No.',
    },
    // Individual
    // {
    //     name: 'individual_first_name',
    //     label: 'First Name of Individual',
    // },
    // {
    //     name: 'individual_middle_name',
    //     label: 'Middle Name of Individual',
    //     required: false,
    // },
    // {
    //     name: 'individual_last_name',
    //     label: 'Last Name of Individual',
    // },
    // {
    //     name: 'perm_address',
    //     label: 'Permenant Address'
    // },
    {
        name: 'nationality',
        options: nationalities,
        hide: form.regist_under_company,
    },
    // {
    //     name: 'contact_name'
    // },
    // {
    //     name: 'email_two',
    //     type: 'email'
    // },
    // {
    //     name: 'fax_add',
    //     label: 'Fax No.'
    // },
    // {
    //     heading: <p>Correspondence address if different to 2a or 2b above, state:</p>
    // },
    // {
    //     name: 'cor_address',
    //     label: 'Correspondence address'
    // },
    // {
    //     name: 'tele_num',
    //     label: 'Telephone No.',
    //     type: 'phone'
    // },
    // {
    //     name: 'reg_num',
    //     label: 'Registration No.'
    // },
    // {
    //     name: 'email',
    //     type: 'email'
    // },
    // {
    //     name: 'fax_num',
    //     label: 'Fax No.'
    // },

    "::Details of the Aircraft Operator",
    {
        name: 'operator_first_name',
        label: 'First Name',
    },
    {
        name: 'operator_middle_name',
        label: 'Middle Name',
        required: false,
    },
    {
        name: 'operator_last_name',
        label: 'Last Name',
    },
    {
        name: 'operator_address',
        label: 'Address',
    },
    // {
    //     name: 'operator_contact',
    //     label: 'Contact Name'
    // },
    {
        name: 'operator_phone',
        label: 'Phone #',
        type: 'phone',
    },
    {
        name: 'operator_email',
        type: 'email',
    },
    {
        name: 'operator_fax',
        label: 'Fax No.'
    },

    "::Details of Operation",
    {
        name:'operation_type',
        label: 'Type of Operation',
        options: [
            'CAR OPS 1',
            'CAR OPS 2A',
            'CAR OPS 2H',
            'CAR OPS 3',
            'CAR OPS 4',
            'ICAO 83bis Agreement'

        ]
    },
    {
        name: 'storage_pro',
        label: 'Is the aircraft under a Storage Programme',
        type: 'select:bool'
    },
    {
        name: 'air_base',
        label: 'Aircraft home base'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
