import React from 'react'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import { OPENMAPFIELDS } from '@modules/open-street-map-field'
import Swal from 'sweetalert2'

export default class DerelictVehicle extends React.Component {
  resource = '/derelict_vehicles'
  form_key = 'derelict_vehicle'

  coordinates = null

  defaults = {
    // latitude: this.coordinates[0],
    // longitude: this.coordinates[1],
  }

  // Delete lines as needed
  // fee           = 99
  free          = true
  postpaid      = false
  skip_location = true
  hide_buttons  = true

  // Allow map component selected coordinates to translate to form latitude and longitude fields
  validate = async values => {
    const update = {}

    if(this.coordinates) {
      update.latitude = this.coordinates[0]
      update.longitude = this.coordinates[1]
    }

    return update
  }

  custom_actions = [
    // Assign Field Officer as Director
    {
      text: 'Assign Field Officer',
      icon: 'user-tie',
      roles: [['officer']],
      test: r => ['pending', 'processing'].includes(r.application_decision),
      fn: async record => {
        const officerList = {};
        const { data } = await $app.axios.get(`${this.resource}/show_field_officers`)
        const officerMap = data.records.map(x => {
          return { id: x.id, name: `${x.first_name} ${x.last_name}` }
        })
        this.setState({ all_dept_officers: officerMap })
        this.state.all_dept_officers.forEach(e => {
          officerList[`${e.id}`] = e.name;
        })

        const assignOfficer = await Swal.fire({
          icon: 'question',
          title: 'Assign record to a Field Officer',
          html: `
            Select which field officer you would wish to assign to this application.
          `,
          input: 'select',
          inputOptions: officerList,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (assignOfficer.isDismissed) return
        const officerID = assignOfficer.value

        await this.submitToNewLevel('approved', record, officerID)
      },
    },
    {
      text: 'Approve',
      icon: 'check-square',
      roles: ['field_officer'],
      test: r => !['approved', 'denied'].includes(r.application_decision),
      fn: record => {
        Swal.fire({
          icon: 'question',
          title:
            'Are you sure that you would like to approve this application?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          showCancelButton: true,
          confirmButtonText: 'Approve',
          confirmButtonColor: '#3085d6',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.submitToNewLevel('approved', record)
          }
        })
      },
    },
    {
      text: 'Deny',
      icon: 'ban',
      roles: ['field_officer'],
      test: r => !['approved', 'denied'].includes(r.application_decision),
      fn: record => {
        Swal.fire({
          icon: 'error',
          title: 'Deny Application',
          text: 'By clicking deny, you are confirming that you are denying this application. Are you sure that you want to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Deny',
          confirmButtonColor: '#C82333',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.submitToNewLevel('denied', record)
          }
        })
      },
    },
  ]

  submitToNewLevel = async (value, record, officerID = null) => {
    const { resource, form_key } = this
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
          field_officer_id: officerID,
        },
      })

      Swal.fire(
        'Status Updated!',
        'The application was successfully updated.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to update this application',
        'error'
      )
      return
    }
  }

  foremost = null

  fields = form => [
    "::Vehicle Information",
    {
      name: 'vehicle_type',
      label: 'Vehicle Type',
      options: [
        'car',
        'bus',
        'van',
        'truck',
        'jeep'
      ],
    },
    {
      name: 'vehicle_owner',
      label: 'Are you the owner of the vehicle?',
      type: 'select:bool',
    },
    {
      name: 'vehicle_owner_desc',
      label: 'Who is the vehicle owner?',
      hide: form.vehicle_owner,
      required: false,
    },
    {
      name: 'vehicle_make',
      options: [
            "Abarth",
            "Alfa Romeo",
            "Aston Martin",
            "Audi",
            "Bentley",
            "BMW",
            "Bugatti",
            "Cadillac",
            "Chevrolet",
            "Chrysler",
            "Citroën",
            "Dacia",
            "Daewoo",
            "Daihatsu",
            "Dodge",
            "Donkervoort",
            "DS",
            "Ferrari",
            "Fiat",
            "Fisker",
            "Ford",
            "Honda",
            "Hummer",
            "Hyundai",
            "Infiniti",
            "Iveco",
            "Jaguar",
            "Jeep",
            "Kia",
            "KTM",
            "Lada",
            "Lamborghini",
            "Lancia",
            "Land Rover",
            "Landwind",
            "Lexus",
            "Lotus",
            "Maserati",
            "Maybach",
            "Mazda",
            "McLaren",
            "Mercedes-Benz",
            "MG",
            "Mini",
            "Mitsubishi",
            "Morgan",
            "Nissan",
            "Opel",
            "Peugeot",
            "Porsche",
            "Renault",
            "Rolls-Royce",
            "Rover",
            "Saab",
            "Seat",
            "Skoda",
            "Smart",
            "SsangYong",
            "Subaru",
            "Suzuki",
            "Tesla",
            "Toyota",
            "Volkswagen",
            "Volvo"
      ],
      required: false,
    },
    {
      name: 'vehicle_model',
      required: false,
    },
    {
      name: 'vehicle_color',
      options: [
        'gray', 'black', 'white',
        'red', 'orange', 'green',
        'blue', 'purple', 'pink',
        'yellow', 'brown'
      ],
    },
    {
      name: 'license_number',
      label: 'Vehicle License Plate Number',
      hint: 'if available',
      required: false
    },
    {
      name: 'vehicle_id',
      label: 'Vehicle Identification Number(VIN)',
      hint: 'if available',
      required: false
    },

    "::Location of Derelict Vehicle",
    {
      name: 'island',
      options: islands
    },
    {
      name: 'street_address'
    },
    ...OPENMAPFIELDS(this),
    {
      name: 'building_numb',
      label: 'Building #',
      type: 'integer',
      required: false,
      hint: 'If vehicle is on private property'
    },
    {
      name: 'building_color',
      required: false,
      hint: 'If vehicle is on private property'
    },
    {
      name: 'additional_direction_info',
      label: 'Additional information to find location of vehicle',
      required: false,
    },

    "::Condition of the Vehicle",
    {
      name: 'vehicle_damaged',
      label:'Is the vehicle damaged?',
      type: 'select:bool'
    },
    {
      name: 'vehicle_abandoned',
      label: 'Does the vehicle appear to have been abandoned?',
      type: 'select:bool'
    },
    {
      name: 'safety_hazard',
      label: 'Is the vehicle creating a safety hazard?',
      type: 'select:bool'
    },

    "::Duration",
    {
      name: 'how_long',
      label: 'How long has the vehicle been at this location?',
      options: [
        'Less than a week',
        '1 to 2 weeks',
        '3 to 4 weeks',
        'More than a month'
      ]
    },

    "::Additional Information",
    {
      name: 'additional_info',
      label: 'Additional Comments or Observations',
      type: 'textarea',
      required: false
    },

    "::Uploads",
    {
      name: 'vehicle_photo_upload',
      label: 'Upload a Photo of the Vehicle',
      key: 'image.0',
      type: 'file:image',
      required: false,
    },
    {
      name: 'extra_photo_upload',
      label: 'Upload an additional Photo of the Vehicle',
      key: 'image.1',
      type: 'file:image',
      required: false,
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
