import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import moment from 'moment'
import { BUSINESS_FIELDS, fullName } from '@modules/form-wizard/utils'
import Swal from 'sweetalert2'

export default class FoodImportFee extends React.Component {
  resource = '/food_import_fees'
  form_key = 'food_import_fee'
  defaults = {}

  // Delete lines as needed
  free = false
  postpaid = true
  skip_location = true
  hide_buttons = true
  // custom_acceptance = true

  validate = () => {
    // TODO
    // Use Multiple Photo Attachments Route for certificate uploads

    return true
  }

  foremost = null

  custom_actions = [
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: ['customs', 'field_officer'],
      test: r => ['pending', 'processing'].includes(r.application_decision),
      fn: async record => {
        const { isConfirmed } = await Swal.fire({
          icon: 'question',
          title: 'Approve Application',
          html: `
            ${fullName(record.user, false)}
            Are you sure you want to approve this application?
          `,
          showCancelButton: true,
          confirmButtonText: 'Approve',
        })
  
        if (isConfirmed) {
          await this.submitToNewLevel('approved', record)
        }
      }
    },
    {
      text: 'Approve For Payment',
      icon: 'check-square',
      roles: ['officer', 'senior_officer'],
      test: r => ['pending', 'processing'].includes(r.application_decision),
      fn: async record => {
        let recommendedAmount = null;

        // Set value of Recommended Amount
        const amountPrompt = await Swal.fire({
          icon: 'question',
          title: 'Amount payable?',
          html: `
            What is your recommended amount to be payable regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'number',
          inputValue: record?.recommended_amount,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (amountPrompt.isDismissed) return

        recommendedAmount = amountPrompt.value

        if (recommendedAmount) {
          const { isConfirmed } = await Swal.fire({
            icon: 'question',
            title: 'Confirm Details',
            html: `
              ${fullName(record.user, false)}
              Are you sure you want the payable amount to be: <b>${recommendedAmount}</b>
            `,
            showCancelButton: true,
            confirmButtonText: 'Submit',
          })
    
          if (isConfirmed) {
            await this.submitToNewLevel('approved', record, recommendedAmount)
          }
        }
      }
    },
    {
      text: 'Deny',
      icon: 'ban',
      roles: ['officer', 'senior_officer', 'customs', 'field_officer'],
      test: r => !['approved', 'denied'].includes(r.application_decision),
      fn: record => {
        Swal.fire({
          icon: 'error',
          title: 'Deny Application',
          text: 'By clicking deny, you are confirming that you are denying this application. Are you sure that you want to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Deny',
          confirmButtonColor: '#C82333',
        }).then(async result => {
          if (result.isConfirmed) {
            const { resource, form_key, props } = this
            const { history, location } = props
            const { axios } = $app

            try {
              await axios.put(resource + '/update_application', {
                [form_key]: {
                  form_num: record.form_num,
                  application_decision: 'denied',
                },
              })

              if (location?.state?.view) {
                const view = { ...location.state.view }
                view.application_decision = 'denied'

                history.replace(location.pathname, {
                  ...location.state,
                  view,
                })
              }

              Swal.fire('Denied!', 'The application has been denied', 'success')

            } catch (error) {
              console.error(error)
              Swal.fire(
                'Oops...',
                'There has been an error with the denial of the application',
                'error'
              )
            }
          }
        })
      },
    },
  ]

  submitToNewLevel = async (value, record, recommendedAmount = null) => {
    const { resource, form_key } = this
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
          recommended_amount: recommendedAmount,
        },
      })

      Swal.fire(
        'Submitted!',
        'The application was successfully updated.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to update this application',
        'error'
      )
      return
    }
  }

  columns = props => [
    {
      name: 'Inspection Date',
      selector: row => row.inspection_date ? moment(row.inspection_date).format('MMMM Do YYYY, h:mm a') : 'N/A',
      sortable: true,
      searchable: r => r.inspection_date,
    },
    {
      name: 'Inspection Time',
      selector: row => row.inspection_time_range || 'N/A',
      sortable: true,
      searchable: r => r.inspection_time_range,
    },
  ]

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'inspection_date',
      type: 'date',
      hide: true,
      view: {
        label: 'Inspection Date',
        value: v => (v ? moment(v).format('MMMM Do YYYY, h:mm a') : 'N/A')
      },
      hint: 'Inspection is only for what is stated on bill of lading',
    },
    {
      name: 'inspection_time_range',
      label: 'Time Range for inspection',
      hide: true,
      options: [
        { label: '7:30 am - 10:30 am', value: '7:30 am - 10:30 am' },
        { label: '11:00 am - 2:30 pm', value: '11:00 am - 2:30 pm' },
        { label: '3:00 pm - 5:30 pm', value: '3:00 pm - 5:30 pm' },
      ],
    },
    {
      name: 'consignee',
      label: 'Consignee',
    },
    {
      name: 'vessel_name',
      label: 'Name of vessel',
    },
    {
      name: 'vessel_number',
      label: 'Vessel Number',
    },
    {
      name: 'voyage_number',
      label: 'Voyage Number',
    },
    {
      name: 'lading_number',
      label: 'Bill of Lading Number'
    },

    ':: Containers',
    {
      name: 'less_than_25',
      label: "Less than 25' (Amount)",
      type: 'integer',
      hint: "Amount of containers that are 25' and less in length ($25 each)",
      onBlur: async (v) => {
        if (form.less_than_25) {
          const fee = 25;
          const charge = v ? parseInt(v) * fee : '$0'
          return { amount_for_less_than_25: `$${charge}` }
        }
      },
    },
    {
      name: 'amount_for_less_than_25',
      label: "Total cost for less than 25'",
      type: 'currency',
      save: false,
      disabled: true,
      view: true,
    },
    {
      name: 'less_than_40',
      label: "Bigger than 25' but not more than 40' (Amount)",
      type: 'integer',
      hint: "Amount of containers that are less than 40' but larger than 25' in length ($50 each)",
      onBlur: async (v) => {
        if (form.less_than_40) {
          const fee = 50;
          const charge = v ? parseInt(v) * fee : '$0'
          return { amount_for_less_than_40: `$${charge}` }
        }
      },
    },
    {
      name: 'amount_for_less_than_40',
      label: "Total cost for less than 40'",
      type: 'currency',
      save: false,
      disabled: true,
      view: true,
    },
    {
      name: 'greater_than_40',
      label: "Greater than 40' (Amount)",
      type: 'integer',
      hint: "Amount of containers that are 40' and greater in length ($80 each)",
      onBlur: async (v) => {
        if (form.greater_than_40) {
          const fee1 = 25;
          const fee2 = 50;
          const charge1 = parseInt(form.less_than_25) * fee1
          const charge2 = parseInt(form.less_than_40) * fee2

          const fee = 80;
          const charge = v ? parseInt(v) * fee : '$0'
          const totalCharge = charge1 + charge2 + charge

          return { amount_for_greater_than_40: `$${charge}`,
            grand_total_container_cost: `$${totalCharge}` }
        }
      },
    },
    {
      name: 'amount_for_greater_than_40',
      label: "Total cost for greater than 40'",
      type: 'currency',
      save: false,
      disabled: true,
      view: true,
    },

    ':: Grand Total Cost',
    {
      name: 'grand_total_container_cost',
      label: "Grand Total of all Containers",
      type: 'currency',
      save: false,
      disabled: true,
      view: {
        value: () => {
          const fee1 = 25;
          const fee2 = 50;
          const fee3 = 80;
          const charge1 = parseInt(form.less_than_25) * fee1
          const charge2 = parseInt(form.less_than_40) * fee2
          const charge3 = parseInt(form.greater_than_40) * fee3
          const charge = charge1 + charge2 + charge3
          return `$${charge}`
        }
      }
    },

    ':: Uploads',
    {
      name:'origin_cert_upload',
      key: 'image.0',
      label:'Export Certificate of Wholesomeness/Certificate of Origin',
      type: 'file:all',
    },
    {
      name:'shipping_invoice_upload',
      key: 'image.1',
      label:'Copy of Shipping Invoice',
      type: 'file:all',
    },
    {
      name:'bill_of_lading_upload',
      key: 'image.2',
      label:'Bill of Lading',
      type: 'file:all',
    },
    {
      name:'entry_cert_upload',
      key: 'image.3',
      label:'Transportation and Entry Certificate',
      type: 'file:all',
      required: false,
    },
    {
      name:'import_cert_upload',
      key: 'image.4',
      label:'Import Certificates or Sanitary and Phytosanitary Certificates',
      hint: 'Issued by BAHFSA',
      type: 'file:all',
      required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
