import Axios from 'axios'
import moment from 'moment'
import YAML from 'yaml'

import { extractKeys, listKeys } from '@helpers/objects'
import { hasAny, isSubset } from '@helpers/arrays'
import ENV from '@constants/env'
import SERVICES_YAML from '@/services.yml'

export default function AppGlobals(
  userdata = '',
  agencies = [],
  services = []
) {
  const prototype = Object.create(null)

  if (userdata) {
    const user = JSON.parse(userdata)
    Object.setPrototypeOf(user, null)

    if (user.id) {
      user.age = moment().diff(user.date_of_birth, 'years')
      user.full_name = listKeys(user, 'first_name', 'middle_name', 'last_name')
        .join(' ')
        .replace(/\s+/, ' ')

      Object.defineProperties(this, {
        roles: { value: Object.freeze(user.roles) },
        token: { value: user.token },
        is_citizen: { value: user.verified_citizenship },
        current_user: { value: Object.freeze(user) },
      })

      Object.defineProperties(prototype, {
        axios: {
          value: Axios.create({
            baseURL: listKeys(ENV, 'WEB_SERVICE_URL', 'API_V').join('/'),
            headers: { Authorization: user.token },
          }),
        },

        admin: {
          get() {
            return !this.hasRole('pilot')
          },
        },

        user: {
          get() {
            return this.roles.length == 1 && this.roles[0] == 'pilot'
          },
        },

        hasRole: {
          value(role) {
            return this.roles.includes(role)
          },
        },

        hasAnyRole: {
          value(roles) {
            return hasAny(this.roles, roles)
          },
        },

        hasAllRoles: {
          value(...roles) {
            return isSubset(this.roles, roles)
          },
        },

        setApplicant: {
          value(person) {
            Object.defineProperty(this, 'applicant', {
              value: Object.freeze(person || this.current_user),
              configurable: true,
            })
          },
        },

        clearApplicant: {
          value() {
            delete this.applicant
          },
        },
      })
    }

    if (agencies?.length && services?.length) {
      Object.defineProperties(prototype, {
        ready: {
          get() {
            return !!this.__data
          },
        },

        __data: {
          value: filterServicesData.call(this, agencies, services),
        },

        agencyCodes: {
          get() {
            return this.__data.agencies?.map(agency => agency.code)
          },
        },

        agencies: {
          get() {
            return this.__data.agencies
          },
        },

        getAgency: {
          value(code = '') {
            if (typeof code == 'number') {
              return this.__data.agencies.find(a => a.__id == code)
            }

            if (code == '*') return this.__data.agencies
            const c = code.toLowerCase()
            return this.__data.agencies.find(a => a.code == c)
          },
        },

        getService: {
          value(identifier = '') {
            return this.__data.services.find(
              s =>
                false ||
                s._aid == identifier ||
                s.path == identifier ||
                s.type == identifier ||
                s.name == identifier
            )
          },
        },

        getServiceProp: {
          value(identifier = '', property = '') {
            return this.getService(identifier)?.[property]
          },
        },

        getServices: {
          value(agency_code = '') {
            if (!agency_code || agency_code == '*') return this.__data.services
            const found = this.__data.services.filter(
              s => s.agency?.code == agency_code
            )
            return found.length ? found : null
          },
        },
      })
    }
  } else {
    Object.defineProperties(this, {
      roles: { value: [] },
      token: { value: null },
    })

    Object.defineProperty(prototype, 'axios', {
      value: Axios.create({
        baseURL: listKeys(ENV, 'WEB_SERVICE_URL', 'API_V').join('/'),
      }),
    })
  }

  Object.setPrototypeOf(this, prototype)

  // Automatically set applicant if `proxy` is in history state
  if (userdata) {
    const p = window.history.state?.state?.proxy
    this.setApplicant(p?.user || p)
  }
}

function filterServicesData(__agencies, __services) {
  const manifest = YAML.parse(SERVICES_YAML)
  // console.log('YAML FILE', manifest)

  const getServiceTypes = services => services.reduce((arr, s) => {
    if (s.include === false || !s._fid) return arr
    return [...arr, s._fid]
  }, [])

  const agencies = __agencies.reduce((arr, agency) => {
    const match = manifest.find(m => m.code == agency.code)
    if (!match) return arr

    // console.log('AGENCY YAML', match);

    const departments = match?.departments
    const depts_code = departments?.map(d => `${d.code}_dispatcher`) || []
    const depts_admin = departments?.map(d => d.admin) || []
    const { code, admin } = match
    const dispatcher = match.dispatch || code + '_dispatcher'
    const allow = Object.freeze([admin, dispatcher, 'support', match.allow].concat(depts_code).concat(depts_admin))
    const icon = /^fa(r|s)\s/.test(match.icon)
      ? match.icon
      : 'fas fa-' + match.icon

    if (
      !this.roles.includes('pilot') &&
      !this.roles.includes('support') &&
      !allow.some(role => this.roles.includes(role))
    ) return arr

    arr.push(
      Object.freeze({
        __proto__: null,
        __id: agency.id,
        id: agency.name,
        name: match.name,
        // short: match.short,
        alias: match.short,
        departments: match?.departments,
        code,
        icon,
        admin,
        allow,
        thumbnail: ENV.WEB_SERVICE_URL + agency.image,
        dispatcher,
        services: match?.services,
        service_types: Object.freeze(getServiceTypes(
          match.departments
            ?.reduce((arr, curr) => [...arr, ...curr.services], [])
            ?? match.services
        )),
        ...extractKeys(
          agency,
          'active',
          'website:url',
          'email',
          'support_email',
          'desc_short:summary',
          'desc',
          'minister',
          'permanent_secretary',
          'phone_number'
        ),
      })
    )

    return arr
  }, [])

  const reduceServiceList = (agency, services) => {
    const fn = (list, service) => {
    if (service.allow && !this.roles.includes(service.allow)) return list
    if (service.hide && this.roles.includes(service.hide)) return list

    if (service.hasOwnProperty('_sub') && !service.name) {
      const obj = {
        subheading: service._sub || '',
        agency,
      }

      list.push(Object.freeze(obj))
      return list
    }

    const obj = Object.create(null)
    delete service.file

    obj.agency = agency
    const dept_code = agency.department_code ? `/${agency.department_code}` : ''
    obj.path = service.path.charAt(0) == '/'
      ? service.path
      : `/${agency.code}${dept_code}/${service.path}`

    if (!service._sid) {
      if ( false
        || (service.citiz === false && this.is_citizen)
        || (service.resid === false && !this.is_citizen)
      ) return list

      obj.title = service.name
      obj._sid = service.name
      list.push(Object.freeze(obj))
      return list
    }

    const config = __services.find(s => s.name == service._sid)

    if (!config) {
      if ( false
        || (service.citiz === false && this.is_citizen)
        || (service.resid === false && !this.is_citizen)
      ) return list

      obj.title = service.name
      obj._sid = service.name
      list.push(Object.freeze(obj))
      return list
    }

    if (!config.active || !(
      this.current_user.sneak_peak.includes(config.name)
      || (config.show_citizen && this.is_citizen)
      || (config.show_permanent_resident && !this.is_citizen)
    )) return list

    obj.under_maintenance = config.maintenance
    obj.instructions = (config.show_note && config.note) || null
    obj.dependencies = Object.freeze(config.required_fields)
    obj.base_fee = parseFloat(config.payment_amount) || null

    obj.locations = Object.freeze(
      config.locations?.reduce((obj, curr) => {
        for (let [prop, val] of Object.entries(curr)) {
          obj[prop] = Object.freeze(val)
        }

        return obj
      }, {})
    )

    obj.messaging = Object.freeze({
      submitted: config.toast_msg?.trim(),
      payment: config.payment_msg?.trim(),
      approved: config.approve_msg?.trim(),
      denied: config.denial_msg?.trim(),
    })

    obj.subsections = Object.freeze(
      extractKeys(
        config,
        'spouse_section:spouse',
        'parents_section:parents',
        'kin_section:kin',
        'employment_section:employment',
        'education_section:education'
      )
    )

    Object.assign(
      obj,
      extractKeys(
        config,
        'desc',
        'desc_short:summary',
        'line_item_no:line_item',
        'delay_payment:postpaid',
        'skip_payment:free',
        'appointments:has_appointment',
        'collection_requirements',
        'business:for_companies',
        'metadata',
        'attachments',
        'sand_dollar'
      ),
      extractKeys(
        service,
        '_fid:type',
        '_sid:name',
        'name:title',
        'alias'
      )
    )

    list.push(Object.freeze(obj))
    return list
    }

    return services.reduce(fn, [])
  }

  const services = manifest.reduce((arr, agency) => {
    const { services, departments, code } = agency

    const details = agencies.find(a => a.code == code)
    if (!details) return arr

    if (departments) {
      for (let dept of departments) {
        const { name, admin, services, code } = dept
        const mergedAgencyDetails = {
          ...details,
          department: name,
          department_code: code,
          admin: admin ?? details.admin,
        }

        arr.push(...reduceServiceList(mergedAgencyDetails, services))
      }

      return arr
    }

    arr.push(...reduceServiceList(details, services))
    return arr
  }, [])

  // console.log('Compiled YAML', Object.create(null, {
  //   agencies: {
  //     value: Object.freeze(
  //       agencies.filter(
  //         ({ code }) => !!services.find(s => !s.subheading && s.agency?.code == code)
  //       )
  //     ),
  //   },

  //   services: {
  //     value: Object.freeze(services),
  //   },
  // }))

  return Object.create(null, {
    agencies: {
      value: Object.freeze(
        agencies.filter(
          ({ code }) => !!services.find(s => !s.subheading && s.agency?.code == code)
        )
      ),
    },

    services: {
      value: Object.freeze(services),
    },
  })
}
