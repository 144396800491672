import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import countries from '@constants/countries'
import { OPENMAPFIELDS } from '@modules/open-street-map-field'

export default class ApprovedTraining extends React.Component {
  resource = '/approved_trainings'
  form_key = 'approved_training'
  defaults = {}

  // Delete lines as needed
//   fee           = 99
  free          = true
//   postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []


  coordinates = null

  // Allow map component selected coordinates to translate to form latitude and longitude fields
  validate = async values => {
    const update = {}

    if(this.coordinates) {
      update.latitude = this.coordinates[0]
      update.longitude = this.coordinates[1]
    }

    return update
  }

  foremost = null

  fields = form => [
    {
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'name',
        label: 'Name of Applicant/Holder',
        hide: form.i_am_applicant != false
    },
    {
        name: 'permanent_address',
        hint: 'Street or PO Box Number',
        hide: form.i_am_applicant != false
    },
    {
        name: 'telephone',
        type: 'phone',
        hide: form.i_am_applicant != false
    },
    {
        name: 'fax',
    },
     {
        name: 'city'
     },
     {
        name: 'island',
        label: 'Island/State/Province',
        options: islands
     },
     {
        name: 'postal_code',
        label: 'Postal.Mailing Code'
     },
     {
        name: 'country',
        options: countries
     },

     ...OPENMAPFIELDS(this),

     {
        name: 'training_course',
        label: 'Identification of Training Courses',
        columns: ['Course Title']
     },
     {
        name: 'special_equip',
        label: 'Equipment Requiring Special Approval',
        columns: ['Equipment Description', 'Location']
     },

     "::Additional Application Attachments",
     {
      name: 'training_upload',
      label: 'TO Training & Procedures Manual',
      key: 'image.0',
      type: 'file:pdf'
     },
     {
      name: 'report_upload',
      label: 'Conformance Report',
      key: 'image.1',
      type: 'file:pdf'
     },
     {
      name: 'facility_upload',
      label: 'Facility Description & Layout',
      key: 'image.2',
      type: 'file:all'
     },
     {
      name: 'records_upload',
      label: 'Proposed student & Staff Records',
      key: 'image.3',
      type: 'file:pdf'
     },
     {
      name: 'grad_upload',
      label: 'Proposed Graduation Certificate',
      key: 'image.4',
      type: 'file:all'
     },
     {
      name: 'transcript_upload',
      label: 'Proposed transcript Records',
      key: 'image.5',
      type: 'file:pdf'
     },
     {
      name: 'management_upload',
      label: 'Management Resumes',
      key: 'image.6',
      type: 'file:pdf'
     },
     {
      name: 'instructor_upload',
      label: 'Instructor Resumes',
      key: 'image.7',
      type: 'file:pdf'
     },
     {
      name: 'instructor_train_upload',
      label: 'Instructor Training Records',
      key: 'image.8',
      type: 'file:pdf'
     },
     {
      name: 'qa_upload',
      label: 'Quality Assurance System',
      key: 'image.9',
      type: 'file:all'
     }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
