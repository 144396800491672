import React from 'react'
import passportPhotoExample from '@helpers/passport-photo-example'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BGCSES, BJCS } from '@constants/national-exams'
import { allConstituencies } from '@constants/prd'

export default class YouthParliamentAlternate extends React.Component {
  resource = '/national_youth_parliaments'
  form_key = 'national_youth_parliament'
  defaults = {}

  // Delete lines as needed
//   fee           = 99
  free          = true
  postpaid      = false
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = form => {
    let shirt_size = form.shirt_size
    if(shirt_size == 'other') shirt_size = form.other_shirt_size;

    const update = {
        areas_of_interest_list: form.areas_of_interest_list.slice(0, 3),
        other_areas_of_interest_list: form.other_areas_of_interest_list.slice(0, 3),
        shirt_size: shirt_size,
    }

    return update
  }

  foremost = null

  includesAny = (arr, vals) => { return vals?.some(v => arr?.includes(v)) };

  childProofLabel = (form) => {
    if (form.choose_upload == 'Birth Certificate') {
      return 'Registered birth certificate of minor'
    }
    else if (form.choose_upload == 'Adoption Certificate') {
      return 'Certified Adoption Certificate'
    }
    else if (form.choose_upload == 'Certified Court Order') {
      return 'Certified Court Order for proof of legal Guardianship'
    }
    else if (form.choose_upload == 'Letter from Social Services') {
      return 'Valid letter from Department of Social Services, where the minor is under state care'
    } else {
      return 'Child Identification Documents'
    }
  }

  youthAge = (form) => {
    let result = 0
    if (form.youth_date_of_birth) {
        const today = new Date();
        const dob = new Date(form.youth_date_of_birth)
        const monthCheck = today.getMonth() >= dob.getMonth()
        const dayCheck = today.getDate() >= dob.getDate()
        const minusYear = monthCheck && dayCheck ? 0 : 1
        result = (today.getFullYear() - dob.getFullYear()) - minusYear
    }

    return result
  }

  fields = form => [
    {
        heading: '*Note: This application will service both Parliament (16-25) & Senate (26-35) applicants.*',
        view: false,
    },

    {
        name: 'i_am_youth',
        label: 'Are you applying for yourself?',
        type: 'select:bool',
        hint: "If you are applying for an applicant under 18, please ensure that only the applicant's information is used to fill out the application below.",
    },

    "::Youth's Details",

    {
        name: 'youth_first_name',
        label: 'First Name',
        hide: form.i_am_youth,
    },
    {
        name: 'youth_middle_name',
        label: 'Middle Name',
        hide: form.i_am_youth,
        required: false,
    },
    {
        name: 'youth_last_name',
        label: 'Last Name',
        hide: form.i_am_youth,
    },
    {
        name: 'youth_nib',
        label: 'NIB #',
        hide: form.i_am_youth,
        type: 'integer',
        required: false,
    },
    {
        name: 'youth_date_of_birth',
        type: 'date',
        label: 'Date of Birth',
        showYearDropdown: true,
        hide: form.i_am_youth,
    },
    {
        name: 'youth_sex',
        label: 'Sex',
        hide: form.i_am_youth,
        options: ['Male', 'Female']
    },
    {
        name: 'citizenship',
        hide: form.i_am_youth,
    },
    {
        name: 'constituency_represent',
        label: 'Which constituency do you want to represent?',
        options: allConstituencies
    },
    {
        name: 'youth_address',
        label: 'Street Address/Settlement',
        hide: form.i_am_youth,
    },
    {
        name: 'phone_contact',
        type: 'phone',
        hide: form.i_am_youth,
    },
    {
        name: 'vaccination_status',
        label: 'COVID-19 Vaccination Status',
        options: ['Fully vaccinated', 'Partially vaccinated (one shot)', 'Not vaccinated']
    },
    {
        name: 'shirt_size',
        options:[
            'small',
            'medium',
            'large',
            'XL',
            'XXL',
            'XXXL',
            'other'
        ]
    },
    {
        name: 'other_shirt_size',
        label: 'Please state your shirt size',
        hide: form.shirt_size !='other'
    },
    {
        name: 'youth_activities_list',
        label: 'List all youth activities/organizations in which you are involved',
        hint: 'Indicate leadership positions',
        columns: ['activity'],
        format: v => v.activity,
    },
    {
        name: 'similar_event',
        label: 'Have you participated in a similar event in the past?',
        hint: 'Speech competitions, Junior/ Regional Parlaiment, etc.',
        type: 'select:bool'
    },
    {
        name: 'event_name',
        label: 'Name of Event',
        hide: !form.similar_event,
    },
    {
        name: 'event_where',
        label: 'Location of Event',
        hide: !form.similar_event,
    },
    {
        name: 'event_date',
        type: 'date',
        label: 'Date of Event',
        showYearDropdown: true,
        hide: !form.similar_event,
    },
    {
        name: 'youth_awards_list',
        label: 'List any special awards you received in the past',
        columns: ['award'],
        format: v => v.award,
    },
    {
        name: 'areas_of_interest_list',
        label: 'Subject matter of interest/ area of interest you would like to work with',
        multi: true,
        fixed: 3,
        options: [
            'Youth',
            "Inclusion & Disabilities",
            'Social Activism',
            'Digital Skills and Technology',
            'Climate Change',
            'Employment',
            'Tourism',
            'Welfare',
            'Housing',
            'Immigration',
            'Education',
            "Arts & Culture",
            "Agriculture & Marine Resources",
            'Food Security',
            'Religion',
            'Sports',
            'Foreign Affairs',
            "Finance and Economic Affairs",
            'Family Island Affairs',
            "Crime & Justice",
            "Energy and Transportation",
            "National Security",
            "Disaster Management",
            "Inclusion & Engagement",
            "Health & Mental Health",
            'Other',
        ]
    },
    {
        name: 'other_areas_of_interest_list',
        label: 'Other areas of interest',
        // fixed: true,
        columns: ['interest'],
        format: v => v.interest,
        hide: !this.includesAny(['Other'], form.areas_of_interest_list),
        required: false,
    },
    {
        name: 'sustainable_dev_goal',
        label: 'Please select a Sustainable Development Goal (SDG) of interest',
        hint: 'If you are unaware of the SDGs you are asked to research them',
        options: [
            {label: "Goal 1: No Poverty", value: 'No Poverty'},
            {label: "Goal 2: Zero Hunger", value: 'Zero Hunger'},
            {label: "Goal 3: Good Health and Well-Being", value: 'Good Health and Well-Being'},
            {label: "Goal 4: Quality Education", value: 'Quality Education'},
            {label: "Goal 5: Gender Equality", value: 'Gender Equality'},
            {label: "Goal 6: Clean Water and Sanitation", value: 'Clean Water and Sanitation'},
            {label: "Goal 7: Affordable and Clean Energy", value: 'Affordable and Clean Energy'},
            {label: "Goal 8: Decent Work and Economic Growth", value: 'Decent Work and Economic Growth'},
            {label: "Goal 9: Industry, Innovation, and Infrastructure", value: 'Industry, Innovation, and Infrastructure'},
            {label: "Goal 10: Reduced Inequalities", value: 'Reduced Inequalities'},
            {label: "Goal 11: Sustainable Cities and Communities", value: 'Sustainable Cities and Communities'},
            {label: "Goal 12: Responsible Consumption and Production", value: 'Responsible Consumption and Production'},
            {label: "Goal 13: Climate Action", value: 'Climate Action'},
            {label: "Goal 14: Life Below Water", value: 'Life Below Water'},
            {label: "Goal 15: Life on Land", value: 'Life on Land'},
            {label: "Goal 16: Peace, Justice and Strong Institutions", value: 'Peace, Justice and Strong Institutions'},
            {label: "Goal 17: Partnerships", value: 'Partnerships'},
        ],
    },

    "::Medical Information",
    {
        name: 'food_allergies',
        label: 'Do you have any allergies?',
        type: 'select:bool'
    },
    {
        name: 'food_allergies_details',
        label: 'Please list them',
        type: 'textarea',
        hide: !form.food_allergies
    },
    {
        name: 'medical_conditions',
        label: 'Do you have any medical conditions?',
        type: 'select:bool'
    },
    {
        name: 'medical_conditions_details',
        label: 'Please list them',
        type: 'textarea',
        hide: !form.medical_conditions
    },
    {
        name: 'medication',
        label: 'Are you taking any medication/s?',
        type: 'select:bool',
    },
    {
        name: 'medication_details',
        label: 'Name(s) of medication(s)',
        type: 'textarea',
        hide: !form.medication
        // hint: 'Type N/A if not applicable'
    },

    "::Academic information",
    {
        name: 'student',
        label: 'Currently a student?',
        type: 'select:bool'
    },
    {
        name: 'name_of_school',
        label: 'What is the name of the college/school being attended?',
        hide: !form.student
    },
    {
        name: 'graduate',
        label: 'Is the youth a graduate?',
        type: 'select:bool'
    },
    {
        name: 'bjcs_passed_list',
        label: `Select all BJC's passed`,
        options: BJCS,
        multi: true
    },
    {
        name: 'bgcses_passed_list',
        label: `Select all BGCSE's passed`,
        options: BGCSES,
        multi: true
    },
    {
        name: 'academic_certificates_list',
        label: 'List any other academic certifcates achievied',
        columns: ['academic_certificate'],
        format: v => v.academic_certificate,
    },
    {
        name: 'why_me',
        label: 'State why you should be selected as a nominee for Youth in Parliament',
        type: 'textarea',
        max: 250,
        maxLength: 250,
        hint: "No more than one (1) paragraph"
    },
    {
        name: 'interesting_facts_list',
        label: 'Share five (5) interesting facts about yourself',
        columns: ['interesting_fact'],
        // fixed: 5,
        format: v => v.interesting_fact,
        // required: false,
    },

    "::Uploads",
    {
        name: 'choose_upload',
        label: "Please upload one of the following documents to verify you are the applicant's parent or guardian",
        options: ['Birth Certificate', 'Adoption Certificate', 'Certified Court Order', 'Letter from Social Services'],
        hide: form.i_am_youth || this.youthAge(form) > 18,
    },
    {
        name: 'child_proof_upload',
        label: this.childProofLabel(form),
        hint: `If the document is not uploaded, you must visit the Division of 
            <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
        key: 'image.0',
        type: 'file:all',
        hide: form.i_am_youth || this.youthAge(form) > 18,
        required: false,
    },
    {
        name: 'guardian_proof_upload',
        label: 'Identification documents of parent or legal guardian',
        hint: `If the document is not uploaded, you must visit the Division of 
            <span style='text-transform: capitalize;'>${form.division_selected}</span> or contact a ${form.division_selected} officer to verify. Failure to do so may disqualify the applicant.`,
        key: 'image.1',
        type: 'file:all',
        hide: form.i_am_youth || this.youthAge(form) > 18 || form.choose_upload != 'Birth Certificate',
        required: false,
    },
    {
        name: 'bjcs_upload',
        label: 'BJCs Upload',
        key: 'image.2',
        type: 'file:image',
        required: false,
    },
    {
        name: 'bgcses_upload',
        label: 'BGCSEs Upload',
        key: 'image.3',
        type: 'file:image',
        required: false,
    },
    {
        name: 'photo_upload',
        label: 'Professional Photo',
        type: 'file:image',
        key: 'image.4'
    },
    {
        name: 'biography_upload',
        type: 'file:all',
        hint: '1-2 pages long',
        key: 'image.5'
    },
    {
        name: 'passport_upload',
        // label: 'Passport',
        type: 'file:all',
        key: 'image.6',
        hint: passportPhotoExample
    },

    "::Emergency Contact",
    {
        name: 'emergency_contact_list',
        rowLabel: 'Emergency Contact',
        fields: [
          {
            name: 'first_name',
            // label: 'First name of parent/guardian'
          },
          {
            name: 'last_name',
            // label: 'Last name of parent/guardian'
          },
          {
            name: 'relationship_to_youth',
            label: 'Relation to youth',
            options: ['parent', 'guardian', 'relative'],
          },
          {
            name: 'phone_contact',
            // label: 'Parent/Guardian phone contact',
            type: 'phone'
          },
          {
            name: 'alt_phone_contact',
            label: 'Alternate phone contact',
            type: 'phone',
            required: false,
          }
        ]
    },

  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
