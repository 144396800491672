/* These are import statements in JavaScript that import various modules and components needed for the
TradeUnionRegistration class to function properly. */
import React, { Fragment } from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import Loader from '@shared/loader'
// import Form from '@modules/form-wizard/form'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import { OPENMAPFIELDS } from '@modules/open-street-map-field'
import Swal from 'sweetalert2'
import toastr from '@modules/toastr'
import { fullName } from '@modules/form-wizard/utils'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import { ValidatorForm } from 'react-form-validator-core'
import DateTimeField from '@shared/form-fields/date'

/* This code exports a React component called `TradeUnionRegistration` as the default export. The
component extends the `React.Component` class and defines various properties and methods that are
used to render a form wizard for registering a trade union. */




export default class CharcoalKiln extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  // async componentDidMount() {
  //   Object.assign(this, extractKeys($app, 'current_user:user'))
  // }
  resource = '/charcoal_kiln_forest_burning_permits'
  form_key = 'charcoal_kiln_forest_burning_permit'
  isRenewal = /renewal/.test(this.props.location.pathname)

  postpaid = false
  pay_on_approval = true
  skip_location = true

  hide_buttons = {
    status: true,
    deny: true,
    fee: true,
  }

  state = { record: null, loading: false, date: new Date() }

  defaults = {
    renewal: this.isRenewal,
    // transfer: this.isTransfer
    first_permit: this.isRenewal ? !this.isRenewal : null,
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: this.isRenewal,
        // transfer: this.isTransfer,
      },
    },
  }


  coordinates = null

  // Allow map component selected coordinates to translate to form latitude and longitude fields
  validate = async values => {
    const update = {}

    if(this.coordinates) {
      update.latitude = this.coordinates[0]
      update.longitude = this.coordinates[1]
    }

    return update
  }


  canned = {
    'TEST CANNED': `
      This is a test canne message; it can be whatever you like.
    `
  }

  foremost = null

  custom_actions = [
    // Assign Field Officer as Director
    {
      text: 'Assign Field Officer',
      icon: 'user-tie',
      roles: [['deputy_director', 'director']],
      test: r => ['pending', 'processing'].includes(r.application_decision),
      fn: async record => {
        const officerList = {};
        const { data } = await $app.axios.get(`${this.resource}/show_field_officers`)
        const officerMap = data.records.map(x => {
          return { id: x.id, name: `${x.first_name} ${x.last_name}` }
        })
        this.setState({ all_dept_officers: officerMap })
        this.state.all_dept_officers.forEach(e => {
          officerList[`${e.id}`] = e.name;
        })

        const assignOfficer = await Swal.fire({
          icon: 'question',
          title: 'Assign record to a Field Officer',
          html: `
            Select which field officer you would wish to assign to this application.
          `,
          input: 'select',
          inputOptions: officerList,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (assignOfficer.isDismissed) return
        const officerID = assignOfficer.value

        await this.submitToNewLevel('approved', record, officerID)
      },
    },

    // Assign Cashier Officer as Director
    {
      text: 'Assign Cashier Officer',
      icon: 'user-tie',
      roles: [['deputy_director', 'director']],
      test: r => ['field officer viewed'].includes(r.application_decision),
      fn: async record => {
        const officerList = {};
        const { data } = await $app.axios.get(`${this.resource}/show_cashier_officers`)
        const officerMap = data.records.map(x => {
          return { id: x.id, name: `${x.first_name} ${x.last_name}` }
        })
        this.setState({ all_dept_officers: officerMap })
        this.state.all_dept_officers.forEach(e => {
          officerList[`${e.id}`] = e.name;
        })

        const assignOfficer = await Swal.fire({
          icon: 'question',
          title: 'Assign record to a Field Officer',
          html: `
            Select which field officer you would wish to assign to this application.
          `,
          input: 'select',
          inputOptions: officerList,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (assignOfficer.isDismissed) return
        const officerID = assignOfficer.value

        await this.submitToNewLevel('approved', record, officerID)
      },
    },

    // Recommend Inspection Date as Field Officer
    {
      text: 'Set Inspection Date',
      icon: 'chevron-right',
      roles: ['field_officer'],
      test: r => r.application_decision == 'director viewed',
      fn: async record => this.setState({ record })
    },

    // Recommend Payable Amount as Cashier
    {
      text: 'Recommend Amount',
      icon: 'question',
      roles: [['cashier_officer']],
      test: r => ['director reviewed'].includes(r.application_decision),
      fn: async record => {
        let recommendedAmount = null;

        // Set value of Recommended Amount
        const amountPrompt = await Swal.fire({
          icon: 'question',
          title: 'Amount to be payable?',
          html: `
            What is your recommended amount to be payable regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'number',
          inputValue: record?.recommended_amount,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (amountPrompt.isDismissed) return

        recommendedAmount = amountPrompt.value

        await this.submitToNewLevel('approved', record, null, recommendedAmount, null)
      },
    },
    // {
    //   text: 'Approve For Payment',
    //   icon: 'check-square',
    //   roles: ['director'],
    //   fn: async record => 
    //     Swal.fire({
    //       title:
    //         'Are you sure that you would like to approve this application for payment?',
    //       text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
    //       icon: 'question',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       confirmButtonText: 'Confirm',
    //     }).then(async result => {
    //       if (result.isConfirmed) {
    //         try {
    //           await $app.axios.put(this.resource + '/update_payment_amount', {
    //             [this.form_key]: {
    //               form_num: record.form_num,
    //             },
    //           })
    //           toastr.success('Success', 'Application successfully approved for payment')
    //         } catch (err) {
    //           console.error(err)
    //           toastr.error('Error', 'Unable to approve application')
    //         }
    //       }
    //     })
    // },
    {
      text: 'Approve For Payment',
      icon: 'check-square',
      roles: ['director'],
      test: r => ['cashier officer viewed'].includes(r.application_decision),
      fn: async record => {
        let licenseNumber
        let recommendedAmount = null;

        // Set value of Recommended Amount
        const amountPrompt = await Swal.fire({
          icon: 'question',
          title: 'Amount to be payable?',
          html: `
            What is your recommended amount to be payable regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'number',
          inputValue: record?.recommended_amount,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (amountPrompt.isDismissed) return

        recommendedAmount = amountPrompt.value

        const { value } = await Swal.fire({
          icon: 'info',
          input: 'text',
          text: 'Please set the permit licence #',
          title: 'Permit Licence Number',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          // inputValidator: val => {
          //   if (Number(val) == 'NaN') {
          //     return 'Please enter a valid amount.'
          //   }
          // }
        })
    
        licenseNumber = value;
    
        if (value) {
          const { isConfirmed } = await Swal.fire({
            icon: 'question',
            title: 'Confirm Licence Number',
            html: `
              ${fullName(record.user, false)}
              Are you sure you want the payable amount to be: <b>${recommendedAmount}</b>
              Are you sure you want the licence number to be: <b>${licenseNumber}</b>
            `,
            showCancelButton: true,
            confirmButtonText: 'Submit',
          })
    
          if (isConfirmed) {
            await this.submitToNewLevel('approved', record, null, recommendedAmount, licenseNumber)
          }
        }
      }
    },
    {
      text: 'Deny',
      icon: 'ban',
      roles: ['director'],
      test: r => !['approved', 'denied'].includes(r.application_decision),
      fn: record => {
        Swal.fire({
          icon: 'error',
          title: 'Deny Application',
          text: 'By clicking deny, you are confirming that you are denying this application. Are you sure that you want to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Deny',
          confirmButtonColor: '#C82333',
        }).then(async result => {
          if (result.isConfirmed) {
            const { resource, form_key, props } = this
            const { history, location } = props
            const { axios } = $app

            try {
              await axios.put(resource + '/update_application', {
                [form_key]: {
                  form_num: record.form_num,
                  application_decision: 'denied',
                },
              })

              if (location?.state?.view) {
                const view = { ...location.state.view }
                view.application_decision = 'denied'

                history.replace(location.pathname, {
                  ...location.state,
                  view,
                })
              }

              Swal.fire('Denied!', 'The application has been denied', 'success')

            } catch (error) {
              console.error(error)
              Swal.fire(
                'Oops...',
                'There has been an error with the denial of the application',
                'error'
              )
            }
          }
        })
      },
    },
  ]

  closeModal = () => this.setState({ record: null })

  onChange = date => this.setState({ date })

  submitToNewLevel = async (value, record, officerID = null, recommendedAmount = null, licenseNumb = null) => {
    const { resource, form_key } = this
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
          field_officer_id: officerID,
          cashier_officer_id: officerID,
          recommended_amount: recommendedAmount,
          license_numb: licenseNumb
        },
      })

      Swal.fire(
        'Submitted!',
        'The application successfully updated.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to update this application',
        'error'
      )
      return
    }
  }

  setAppt = async () => {
    const { props, state, resource, form_key } = this
    const { history, location } = props
    const { date, record } = state

    this.setState({ loading: true })

    try {
      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          inspection_date: new Date(date),
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'field officer viewed'

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      toastr.success('Success', 'Application successfully updated')
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing your application',
        'error'
      )
    } finally {
      this.setState({ record: null, loading: false })
    }
  }

  columns = props => [
    {
      name: 'Inspection Date',
      selector: row => row.inspection_date || 'N/A',
      sortable: true,
      searchable: r => r.inspection_date,
    },
    {
      name: 'Payment Set',
      selector: row => row.recommended_amount || 'N/A',
      sortable: true,
      searchable: r => r.recommended_amount,
    },
  ]

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'renewal',
      label: 'Renewal?',
      type: 'select:bool',
      hide: true,
      view: true,
      save: true,
    },
    {
      name: 'inspection_date',
      type: 'date',
      hide: true,
      view: {
        label: 'Inspection Date',
        value: v => (v ? moment(v).format('MMMM Do YYYY, h:mm:ss a') : 'N/A')
      },
    },
    {
      name: 'recommended_amount',
      hide: true,
      view: {
        label: 'Recommended Amount',
        value: v => v ? v : 'N/A'
      },
    },

    '::Forest Estate Details',
    {
      name:'forest_name',
      label:'Name of forest estate',
    },
    // {
    //   name:'forest_acreage',
    //   label:'approximate acreage of forest estate',
    // },
    {
      name:'forest_island',
      label:'Island',
      options: islands,
    },
    // {
    //   name:'forest_city',
    //   label:'',
    // },
    {
      name:'forest_street_address',
      label:'Address',
    },

    ...OPENMAPFIELDS(this),

    {
      name:'forest_settlement',
      label:'Nearest Settlement',
    },

    '::Specific Information',
    {
      name:'first_permit',
      type: 'select:bool',
      label:'Is this your first application for a Burning Permit for a charcoal kiln?',
      disabled: this.isRenewal,
      save: true,
    },
    {
      name:'last_app_numb',
      label:'Application No.',
      // type: 'integer',
      hide: form.first_permit,
    },
    {
      name:'last_app_date',
      label:'Date of application',
      type:'date',
      hide: form.first_permit,
    },
    {
      name:'app_successful',
      type: 'select:bool',
      label: 'Was the application successful?',
      hide: form.first_permit,
    },
    {
      name:'last_permit_numb',
      label:'Permit No.',
      // type: 'integer',
      hide: !form.app_successful,
    },
    {
      name:'last_permit_date',
      label:'Date of issue',
      type:'date',
      hide: !form.app_successful,
    },
    {
      name: 'wood_types_list',
      label: 'Please indicate the type (s) (species) and the source of fuel wood to be used in the proposed activity',
      // hint: 'Applicable for instrument class only',
      columns: ['info'],
      format: v => v.info,
    },
    {
      name:'occupiers_notified',
      label: 'State whether the owners or occupiers of adjoining property have been notified of the proposal Charcoal kiln activity',
      type: 'select:bool',
    },

    '::Uploads',
    {
      name:'burning_plan_upload',
      key: 'image.1',
      // label:'',
      type: 'file:all',
    },
    {
      name:'management_plan_upload',
      key: 'image.2',
      // label:'',
      type: 'file:all',
      hint: 'in compliance with requirements of Regulation 17 (3) (b)',
    },
  ]

  render = () => {
    const { loading } = this.state
    // console.log($app.current_user.id)

    return (
      <Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={!!this.state.record && !this.state.loading}
          onClose={this.closeModal}
          classNames={{ modal: 'w-full sm:w-128' }}
          center
        >
        <header className='modal-header'>
          <h4 className='mb-0'>Set Inspection Date</h4>
        </header>
        <ValidatorForm onSubmit={this.setAppt}>
          <article className='modal-body'>
            <label htmlFor='date' className='form-label'>
              Please set the applicant's inspection date
            </label>
            <DateTimeField
              name='date'
              dateFormat="MMMM d, yyyy h:mm aa"
              timeInputLabel="Time:"
              showTimeInput
              icon={null}
              minDate={new Date()}
              selected={this.state.date}
              value={this.state.date}
              onChange={this.onChange}
              validators={['required']}
              errorMessages={['Please enter a valid date']}
              className='form-control'
              required
            />
          </article>
          <footer className='modal-footer'>
            <button type='button' className='btn' onClick={this.closeModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </footer>
        </ValidatorForm>
      </Modal>
      </Fragment>
    )
  }
}
