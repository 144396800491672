import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class AirworthinessCert extends React.Component {
  resource = '/airworthiness_certs'
  form_key = 'airworthiness_cert'
  isRenewal = /renewal/.test(this.props.location.pathname)

  defaults = {
    renewal: this.isRenewal,
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        renewal: this.isRenewal,
        // transfer: this.isTransfer,
      },
    },
  }

  // Delete lines as needed
//   fee           = 99
  free          = true
//   postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'renewal',
        label: 'Renewal?',
        type: 'select:bool',
        hide: true,
        view: true,
        save: true,
    },

    "::Details of Aircraft",
    {
        name: 'registration_mark'
    },
    {
        name: 'aircraft_designation',
        label: `Manufacturer's Designation of Aircraft`,
        type: 'textarea'
    },
    {
        name: 'serial_no',
        label: 'Serial Number'
    },
    {
        name: 'manufacturer',
        label: 'Manufacturer of Aircraft'
    },
    {
        name: 'manufacture_date',
        label: 'Date of Manufacture',
        type: 'date',
        showYearDropdown:true
    },
    {
        name:'data_sheet',
        label: 'Type Certificate Data Sheet (TCDS) to which the aircraft conforms',
        hint:
            `Please state the EASA, FAA, TCCA, ANAC or UK CAA Type Certificate Data Sheet with which the aircraft
            complies for registering the aircraft in The Bahamas. See CAR 21.21 for full details.`,
    },
    {
        name: 'used',
        label: 'New or Used?',
        options: ['used', 'new']
    },
    {
        name: 'airframe_hours'
    },
    {
        name:'cycles'
    },
    {
        name: 'current_c',
        label: 'Current C of A No',
        hide: !this.isRenewal,
    },
    {
        name: 'current_expiry',
        label: 'Current C of A Expiry',
        hide: !this.isRenewal,
    },
    "::Details Of Nominated Airworthiness Coordinator / Postholder For Continuing Airworthiness",
    {
        name: 'first_name'
    },
    {
        name: 'last_name'
    },
    {
        name: 'telephone_no'
    },
    {
        name: 'email',
        type: 'email'
    },
    "::Details of Aircraft Records and Documents Inspection",
    {
        name: 'aorcraft_records',
        label: 'Date and location of aircraft records and documents inpsection',
        columns: [
            {
                name: 'date',
                type: 'date',
                showYearDropdown: true
            },
            {
                name: 'location'
            }
        ]
    },
    {
        name: 'host_organization',
        label: 'Host Organisation where the aircraft records and documents will be inspected'
    },
    {
        name: 'person_details',
        label: 'Details of person presenting the aircraft records and documents for inspection'
    },
    {
        name: 'person_fname',
        label: 'First Name'
    },
    {
        name: 'person_lname',
        label: 'Last Name'
    },
    {
        name: 'telephone',
        type: 'phone'
    },
    {
        name: 'extra_email',
        label: 'Email',
        type: 'email'
    },
    "::Details of Aircraft Physical inspection",
    {
        name: 'inspect_date',
        label: 'Date and location of aircraft records and documents inpsection',
        columns: [
            {
                name: 'date',
                type: 'date',
                showYearDropdown: true
            },
            {
                name: 'location'
            }
        ]
    },
    {
        name: 'host_organization_second',
        label: 'Host Organisation where the aircraft records and documents will be inspected'
    },
    {
        name: 'persons_details',
        label: 'Details of person presenting the aircraft for physical inspection'
    },
    {
        name: 'persons_fname',
        label: 'First Name'
    },
    {
        name: 'persons_lname',
        label: 'Last Name'
    },
    {
        name: 'telephone',
        type: 'phone'
    },
    {
        name: 'additional_email',
        label: 'Email',
        type: 'email'
    },
    "::Modifications Embodied",
    {
        name: 'list_modifications',
        label: 'List below all modifications embodied on the Aircraft, its Engine(s) or Propeller(s), including changes to any required equipment, since the previous C of A issue or renewal inspection, including the applicable Approval reference number.' ,
        rowLabel: 'Modification',
        fields: [
            {
                name: 'engine',
                required: false
            },
            {
               name: 'propeller',
               required: false
            },
            {
                name: 'change made',
                type: 'textarea',
                required: false
            }, 
            {
                name: 'approval Reference Number',
                required: false
            },
        ],
        required: false
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
